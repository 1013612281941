import moment from 'moment';
import Plot from 'react-plotly.js';
import React from 'react';
import PropTypes from 'prop-types';

import NoData from '../../Components/NoData';
import {
  calculateAverageForKey, getAlertZonesPlots, getPlotData, reducer, round, sendRequest,
} from '../../utils';
import Loader from '../../Components/Loader';
import { useFetchAlertZones } from '../../hooks';

const TemperaturePlot = ({
  patient, startDate, endDate, isActive,
}) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    { data: [], isLoading: true, isError: false },
  );
  const [, alertZones] = useFetchAlertZones(patient.id, 'Thermometer');

  React.useEffect(() => {
    if (!isActive) {
      // Do not load anything while not active
      return;
    }

    dispatch({ type: 'FETCH_INIT' });

    const opts = {
      entry_type: 'Thermometer',
      date_from: moment(startDate).format().slice(0, 10),
      date_to: moment(endDate).format().slice(0, 10),
      user_id: patient.id,
    };

    sendRequest('get_manual_entries_by_user_id', 'GET', opts)
      .then((response) => {
        if (response.status === 'Failure') {
          dispatch({
            type: 'FETCH_FAILURE',
            error: response.errors,
            payload: [],
          });
        } else {
          const rawData = response.entry_data;

          rawData.sort((a, b) => {
            const d1 = moment(a.date_to_date, 'MM/DD/YYYY');
            const d2 = moment(b.date_to_date, 'MM/DD/YYYY');
            if (d1 > d2) {
              return 1;
            }
            if (d1 < d2) {
              return -1;
            }
            return 0;
          });

          dispatch({
            type: 'FETCH_SUCCESS',
            payload: rawData,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
      });
  }, [startDate, endDate, isActive]);

  React.useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [isActive]);

  if (state.isLoading) {
    return (
      <Loader />
    );
  }

  if (!state.data.length) {
    return (
      <NoData />
    );
  }

  let plot = getPlotData({
    rawData: state.data,
    xAttr: 'date_to_date',
    yAttr: 'temperature',
    label: 'Temperature, °C',
    color: '#2196f3',
    aggregate: true,
    summarize: false,
    round: false,
  });

  // Add alert zones
  const alertZonePlots = getAlertZonesPlots(alertZones, plot[0].x, '°C', '#ef9a9a');

  plot = [...plot, ...alertZonePlots.flat()];

  const averageWeight = round(calculateAverageForKey(state.data, 'temperature'), 2);

  const minValue = Math.min(...plot[0]?.y);
  const maxValue = Math.max(...plot[0]?.y);
  const mediumValue = round((minValue + maxValue) / 2);

  return (
    <>
      <div className="row">
        <div className="col s12 mb4">
          <Plot
            data={plot}
            style={{ width: '100%' }}
            layout={{
              legend: { x: 0.01, y: -0.5, orientation: 'h' },
              margin: {
                l: 50, r: 20, b: 50, t: 50, pad: 2,
              },
              autosize: true,
              yaxis: {
                title: 'Temperature, °C',
                nticks: 3,
                tickvals: [minValue, mediumValue, maxValue],
              },
              xaxis: {
                title: 'Date',
              },
            }}
            config={{
              displayModeBar: false,
            }}
          />
        </div>
      </div>

      <div className="row mt4">
        <div className="col s12 m6 offset-m3">
          <div className="card blue-grey">
            <div className="card-content white-text">
              <span className="card-title text-center">Average Temperature</span>
              <div className="center-align">
                {
                state.data.length
                  ? (
                    <>
                      <h4>{averageWeight}</h4>
                      °C
                    </>
                  )
                  : <h4>Not Enough Data</h4>
              }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

TemperaturePlot.propTypes = {
  patient: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default TemperaturePlot;
