import React from 'react';
import ChangePasswordForm from './ChangePasswordForm';
import GeneralSettingsForm from './GeneralSettingsForm';

const MySettings = () => (
  <div>
    <h4 className="mb4">My Settings</h4>

    <GeneralSettingsForm />

    <ChangePasswordForm />
  </div>
);

export default MySettings;
