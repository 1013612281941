import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
// import M from '@materializecss/materialize';

const InvitePatient = () => {
  const [error] = React.useState('');
  const [data, setData] = React.useState({});
  const history = useHistory();

  const onFormSubmit = (e) => {
    e.preventDefault();

    // Get form content and print it to console
    const data = new FormData(e.target);
    setData(Object.fromEntries(data));
  };

  const onCancelClick = (e) => {
    e.preventDefault();

    // Redirect back
    history.goBack();
  };

  return (
    <div className="container mt4">
      <h4 className="mb4">Add new patient</h4>

      <div className="mb4">
        Et in suscipit expedita et id dolorum pariatur nesciunt omnis quibusdam autem perspiciatis.
        Facere illum ducimus officiis rerum veniam voluptas odit ex impedit excepturi aut delectus.
      </div>

      <div className="row">
        <form className="col s12" onSubmit={onFormSubmit}>
          <div className={`form-errors ${error ? '' : 'hide'}`}>{error}</div>
          <div className="row">
            <div className="input-field col s6">
              <input
                id="first_name"
                name="first_name"
                type="text"
                placeholder="Patient First Name"
                className="validate"
                required
              />
              <label htmlFor="first_name">First Name</label>
            </div>

            <div className="input-field col s6">
              <input
                id="last_name"
                name="last_name"
                type="text"
                placeholder="Patient Last Name"
                className="validate"
                required
              />
              <label htmlFor="last_name">Last Name</label>
            </div>
          </div>

          <div className="row">
            <div className="input-field col s12">
              <input
                id="email"
                name="email"
                type="email"
                placeholder="Patient Email"
                className="validate"
                required
              />
              <label htmlFor="email">Email</label>
            </div>
          </div>

          <div className="row">
            <div className="input-field col s12">
              <input
                id="date_of_birth"
                name="date_of_birth"
                type="date"
                placeholder="Patient Date of Birth"
                className="validate"
                required
              />
              <label htmlFor="date_of_birth">Date of Birth</label>
            </div>
          </div>

          <button type="submit" className="btn black mt2 mr2 mb2">Add</button>
          <button type="button" onClick={onCancelClick} className="btn white black-text mt2 mb2">Cancel</button>

          {
            Object.keys(data).length > 0 && (
              <div className="mt4">
                <h6>Form data</h6>
                <pre>{JSON.stringify(data, null, 2)}</pre>
              </div>
            )
          }
        </form>
      </div>
    </div>
  );
};

export default InvitePatient;
