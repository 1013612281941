import React from 'react';
import Plot from 'react-plotly.js';
import PropTypes from 'prop-types';

import M from '@materializecss/materialize';
import moment from 'moment';
import NoData from '../../Components/NoData';
import Table from '../../Components/Table';
import { reducer, round, sendRequest } from '../../utils';
import Loader from '../../Components/Loader';
import Select from '../../Components/Select';

const EntryActions = ({ row: { original: cell } }) => {
  const modalEl = React.useRef(null);
  const [plot, setPlot] = React.useState([]);

  React.useEffect(() => {
    if (modalEl.current && plot) {
      M.Modal.init(modalEl.current, {});
    }
  });

  React.useEffect(() => {
    // Generate plot data for ECG
    if (cell.original_entry_type === 'Pulse') {
      const y = JSON.parse(cell.ecg_data);
      const x = Array.from(Array(y.length).keys());

      const plot = [
        {
          x,
          y,
          type: 'scatter',
          mode: 'lines',
          marker: {
            color: '#f44336',
          },
          name: 'ECG',
        },
      ];

      setPlot(plot);
    }
  }, [cell]);

  // This is needed to resize graph in modal window
  const onModalShow = () => window.dispatchEvent(new Event('resize'));

  // For now we have actions only for ECG
  if (cell.original_entry_type === 'Pulse') {
    const minValue = Math.min(...plot[0]?.y || []);
    const maxValue = Math.max(...plot[0]?.y || []);
    const mediumValue = round((minValue + maxValue) / 2);

    return (
      <div>
        <a
          className="waves-effect waves-light btn black modal-trigger"
          href={`#modal-${cell.id}`}
          onClick={onModalShow}
        >
          View ECG
        </a>

        <div id={`modal-${cell.id}`} className="modal modal-fixed-footer" ref={modalEl}>
          <div className="modal-content">
            <Plot
              data={plot}
              layout={{
                legend: { x: 0.01, y: -0.5, orientation: 'h' },
                margin: {
                  l: 60, r: 20, b: 50, t: 50, pad: 2,
                },
                autosize: true,
                yaxis: {
                  title: 'ECG',
                  nticks: 3,
                  tickformat: 'd',
                  tickvals: [minValue, mediumValue, maxValue],
                },
                xaxis: {
                  title: 'Time',
                },
                paper_bgcolor: 'rgba(0,0,0,0)',
                plot_bgcolor: 'rgba(0,0,0,0)',
              }}
              config={{
                displayModeBar: false,
                responsive: true,
              }}
            />

          </div>
          <div className="modal-footer">
            <a href="#!" className="modal-close waves-effect btn-flat">Close</a>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};

EntryActions.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      original_entry_type: PropTypes.string.isRequired,
      ecg_data: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

const ENTRY_TYPES = {
  BPM: 'Blood Pressure Monitor',
  Pulse: 'ECG',
  Weight: 'Scale',
  PulseOximeter: 'Pulse Oximeter',
  Thermometer: 'Temperature',
  BGM: 'Blood Glucose Monitor',
};
const ENTRY2COLUMNS = {
  '': ['id', 'datetime', 'entry_type', 'systolic', 'diastolic', 'spo2', 'pulse', 'weight', 'temperature', 'bgm'],
  BPM: ['id', 'datetime', 'systolic', 'diastolic', 'pulse'],
  Pulse: ['id', 'datetime', 'pulse', 'actions'],
  Weight: ['id', 'datetime', 'weight'],
  PulseOximeter: ['id', 'datetime', 'spo2', 'pulse'],
  Thermometer: ['id', 'datetime', 'temperature'],
  BGM: ['id', 'datetime', 'bgm'],
};
const COLUMNS = [
  {
    Header: 'Date / Time',
    accessor: 'datetime',
    label: 'Date / Time',
  },
  {
    Header: 'ECG',
    accessor: 'ecg_data',
    label: 'ECG',
  },
  {
    Header: 'Vital',
    accessor: 'entry_type',
    label: 'Vital',
  },
  {
    Header: 'Systolic',
    accessor: 'systolic',
    label: 'Systolic',
  },
  {
    Header: 'Diastolic',
    accessor: 'diastolic',
    label: 'Diastolic',
  },
  {
    Header: () => (
      <>
        SpO
        <sub>2</sub>
      </>
    ),
    accessor: 'spo2',
    label: 'SpO2',
  },
  {
    Header: 'Pulse',
    accessor: 'pulse',
    label: 'Pulse',
  },
  {
    Header: 'Weight',
    accessor: 'weight',
    label: 'Weight',
  },
  {
    Header: 'Temperature',
    accessor: 'temperature',
    label: 'Temperature',
  },
  {
    Header: 'Glucose',
    accessor: 'bgm',
    label: 'Glucose',
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    label: 'Actions',
    Cell: EntryActions,
    disableSortBy: true,
  },
];

const PatientEntries = ({
  patient, startDate, endDate, isActive, withDownloadButton, withPagination,
}) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    { data: [], isLoading: true, isError: false },
  );
  const [data, setData] = React.useState([]);
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [filter, setFilter] = React.useState('');

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    if (!isActive) {
      // Do not load anything while not active
      return;
    }

    const opts = {
      date_from: moment(startDate).format().slice(0, 10),
      date_to: moment(endDate).format().slice(0, 10),
      user_id: patient.id,
    };

    const promises = Object.entries(ENTRY_TYPES).map(
      ([type]) => sendRequest('get_manual_entries_by_user_id', 'GET', { ...opts, entry_type: type }),
    );

    Promise.all(promises)
      .then((responses) => {
        const failedResponses = responses.filter((r) => r.status !== 'Success');
        if (failedResponses.length === responses.length) {
          // All requests are failed!
          dispatch({ type: 'FETCH_FAILURE', error: failedResponses[0].errors, payload: [] });
          setData([]);
        } else {
          const rawData = [].concat(...responses.map((r) => r.entry_data || []));

          rawData.sort((a, b) => {
            const d1 = moment(a.date_to_date, 'MM/DD/YYYY');
            const d2 = moment(b.date_to_date, 'MM/DD/YYYY');
            if (d1 > d2) {
              return 1;
            }
            if (d1 < d2) {
              return -1;
            }
            return 0;
          });

          // Add profile weight if no scale entries returned by API
          if (!rawData.filter((e) => e.entry_type === 'Weight').length) {
            rawData.push({
              id: '-',
              date_for_update: moment.utc(patient.updated_at, 'YYYY-MM-DD hh:mm:ss').local().format('MMM D/YYYY'),
              time: moment.utc(patient.updated_at, 'YYYY-MM-DD hh:mm:ss').local().format('h:m A'),
              entry_type: 'Weight',
              notes: 'Profile Weight',
              weight: patient.weight,
              weight_type: patient.weight_type,
            });
          }

          // Filter options based on entries returned with API
          const entryTypes = [...new Set(rawData.map((e) => e.entry_type))];
          const filterOptions = Object.fromEntries(
            [['', 'All'], ...Object.entries(ENTRY_TYPES).filter((et) => entryTypes.includes(et[0]))],
          );

          dispatch({ type: 'FETCH_SUCCESS', payload: rawData });
          setData(rawData);
          setFilterOptions(filterOptions);
        }
        setFilter('');
      })
      .catch(() => {
        dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' });
      });
  }, [startDate, endDate, isActive]);

  React.useEffect(() => {
    if (filter) {
      setData(state.data.filter((i) => i.entry_type === filter));
    } else {
      setData(state.data);
    }
  }, [filter]);

  if (state.isLoading) {
    return (
      <Loader />
    );
  }

  if (!data.length) {
    return (
      <NoData />
    );
  }

  const entries = data.map(
    (e) => ({
      id: e.id,
      entry_type: ENTRY_TYPES[e.entry_type],
      // We use additional attribute because entry_type will be replaced with user friendly value
      original_entry_type: e.entry_type,
      datetime: `${e.date_for_update} ${e.time}`,
      systolic: e.systolic,
      diastolic: e.diastolic,
      spo2: e.spo2,
      pulse: e.bpm_pulse || e.pulse_oxi || e.pulse,
      // API returns 0.0 for non-weight entries, replace it with empty values
      weight: e.weight === '0.0' ? '' : `${e.weight} ${e.weight_type}`,
      ecg_data: e.ecg_data,
      temperature: e.temperature ? `${e.temperature} °C` : '',
      bgm: e.bgm ? `${e.bgm} mg/dL` : '',
    }),
  );

  const columns = COLUMNS.filter((e) => ENTRY2COLUMNS[filter].includes(e.accessor));

  return (
    <>
      <div className="input-field col s12 m6 hide-on-print">
        <Select
          name="entry_type"
          label="Vital"
          values={filterOptions}
          defaultValue=""
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>
      <Table
        columns={columns}
        data={entries}
        withDownloadButton={withDownloadButton}
        centered
        downloadFilename={`${patient.first_name}_${patient.last_name}_entries.csv`}
        defaultPageSize={withPagination ? 25 : entries.length}
      />
    </>
  );
};

PatientEntries.propTypes = {
  patient: PropTypes.shape({
    id: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    weight: PropTypes.string.isRequired,
    weight_type: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
  }).isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  isActive: PropTypes.bool.isRequired,
  withDownloadButton: PropTypes.bool.isRequired,
  withPagination: PropTypes.bool,
};

PatientEntries.defaultProps = {
  withPagination: true,
};

export default PatientEntries;
