import M from '@materializecss/materialize';
import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import moment from 'moment';
import Loader from '../Components/Loader';
import Table from '../Components/Table';
import {
  mapUnits, reducer, sendRequest,
} from '../utils';
import { useUser } from '../hooks';

const TYPES_TO_ATTRIBUTES = {
  BPM: ['systolic', 'diastolic'],
  Weight: ['weight'],
  Pulse: ['pulse'],
  PulseOximeter: ['spo2'],
  Thermometer: ['temperature'],
  BGM: ['bgm'],
};

const PatientActions = ({ row: { original: cell } }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [patientId] = React.useState(cell.id);
  const [isFrozen, setIsFrozen] = React.useState(cell.is_frozen);

  const modalRemoveConnectionConfirm = React.useRef(null);
  const modalFreezePatientConfirm = React.useRef(null);

  React.useEffect(() => {
    if (modalRemoveConnectionConfirm.current) {
      M.Modal.init(modalRemoveConnectionConfirm.current, {});
    }

    if (modalFreezePatientConfirm.current) {
      M.Modal.init(modalFreezePatientConfirm.current, {});
    }
  });

  const handleFreezePatient = React.useCallback(() => {
    setIsLoading(true);

    sendRequest('freeze_connected_patient', 'PATCH', { patient_id: patientId })
      .then((response) => {
        if (response.status === 'Failure') {
          // eslint-disable-next-line no-console
          console.log(response.errors);
        } else {
          setIsFrozen(true);
          setIsLoading(false);
        }
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.log('Something went wrong with freezing patient');
      });
  });

  const handleUnfreezePatient = () => {
    setIsLoading(true);

    sendRequest('unfreeze_connected_patient', 'PATCH', { patient_id: patientId })
      .then((response) => {
        if (response.status === 'Failure') {
          // eslint-disable-next-line no-console
          console.log(response.errors);
        } else {
          setIsFrozen(false);
          setIsLoading(false);
          cell.is_frozen = false;
        }
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.log('Something went wrong with unfreezing patient');
      });
  };

  const handleRemoveConnection = () => {
    setIsLoading(true);

    sendRequest('delete_connected_patient', 'DELETE', { patient_id: patientId })
      .then((response) => {
        if (response.status === 'Failure') {
          // eslint-disable-next-line no-console
          console.log(response.errors);
        } else {
          // Refresh page
          window.location.reload();
        }
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.log('Something went wrong with removing patient');
      });
  };

  if (isLoading) {
    return <Loader />;
  }

  const freezeModalId = `freeze-modal-${cell.id}`;
  const removeModalId = `remove-modal-${cell.id}`;

  return (
    <>
      <Link to={`/patients/${patientId}`} className="btn black mb2">Open</Link>

      <br />

      {
      isFrozen
        ? <button type="button" className="btn white black-text mb2" onClick={handleUnfreezePatient}>Unfreeze</button>
        : <button type="button" data-target={freezeModalId} className="btn white black-text mb2 modal-trigger">Freeze</button>
      }

      <br />

      <button type="button" data-target={removeModalId} className="btn red modal-trigger">Remove</button>

      {/* Modals */}
      <div ref={modalRemoveConnectionConfirm} id={removeModalId} className="modal confirm-modal">
        <div className="modal-content">
          <h5>Remove Connection</h5>
          <p>Are you sure you want to remove this patient from the patient dashboard?</p>
        </div>
        <div className="modal-footer">
          <button type="button" className="modal-close waves-effect btn-flat" onClick={handleRemoveConnection}>Yes</button>
          <button type="button" className="modal-close waves-effect btn-flat">No</button>
        </div>
      </div>

      <div ref={modalFreezePatientConfirm} id={freezeModalId} className="modal confirm-modal">
        <div className="modal-content">
          <h5>Freeze Patient</h5>
          <p>
            Are you sure you want to freeze this patient? You will not receive any new measurements from the patient.
            Patient will still be on the dashboard, but will be marked as frozen.
          </p>
        </div>
        <div className="modal-footer">
          <button type="button" className="modal-close waves-effect btn-flat" onClick={handleFreezePatient}>Yes</button>
          <button type="button" className="modal-close waves-effect btn-flat">No</button>
        </div>
      </div>
    </>
  );
};

PatientActions.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

const TooltippedCellValue = ({ row: { original }, column: { id } }) => {
  /* Add data-tip */
  const rawEntry = original[`${id}_raw`];

  if (rawEntry) {
    const date = rawEntry.date_to_date;
    const { time } = rawEntry;
    return <span className="tooltipped" data-position="top" data-tooltip={`${time}<br />${date}`}>{original[id]}</span>;
  }

  return <span>{original[id]}</span>;
};

TooltippedCellValue.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  column: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

const ChronicConditions = ({ row: { original: cell } }) => {
  const conditions = cell.chronic_conditions || [];

  return (
    <>
      {
        conditions.map((condition) => (
          <span key={condition} className="chip mr1">{condition}</span>
        ))
      }
    </>
  );
};

ChronicConditions.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      chronic_conditions: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
};

ChronicConditions.defaultProps = {
  row: {
    original: {
      chronic_conditions: '',
    },
  },
};

const checkOutOfRange = (alertZones, entryType, latestEntry) => {
  // Check if the latest entry is out of alert zones
  let outOfZone = false;

  if (latestEntry) {
    const alertZonesForEntryType = alertZones.filter((az) => az.field_type === entryType);

    // Check if value out of alert zones
    alertZonesForEntryType.forEach((az) => {
      TYPES_TO_ATTRIBUTES[entryType].forEach((attr) => {
        // For BPM skip alert zones for systolic/diastolic when attribute is diastolic/systolic
        if (entryType === 'BPM' && az.field_name.toLowerCase() !== attr) {
          return;
        }

        let lowValue = Number(az.low_value);
        let highValue = Number(az.high_value);
        const latestEntryVal = Number(latestEntry[attr]);

        // Weight and BPM can be in multiple units
        // Due to API limitations we have to add this hack
        if (entryType === 'Weight') {
          lowValue = mapUnits(lowValue, az.value_unit, latestEntry.weight_type);
          highValue = mapUnits(highValue, az.value_unit, latestEntry.weight_type);
        }

        if (entryType === 'BPM') {
          lowValue = mapUnits(lowValue, az.value_unit, 'mmHg');
          highValue = mapUnits(highValue, az.value_unit, 'mmHg');
        }

        if (latestEntryVal < lowValue || latestEntryVal > highValue) {
          outOfZone = true;
        }
      });
    });

    return outOfZone;
  }

  return false;
};

const prepareDataForTable = (rawPatients) => {
  // Form array of objects

  const data = rawPatients.map((rawPatient) => {
    // Find the latest measurements
    const { entries } = rawPatient;

    const patientInfo = {
      id: rawPatient.id,
      first_name: rawPatient.first_name,
      last_name: rawPatient.last_name,
      chronic_conditions: rawPatient.chronic_conditions,
      // BPM
      BPM: entries.BPM.latest ? `${entries.BPM.latest.systolic}/${entries.BPM.latest.diastolic} mmHg` : '-',
      BPM_raw: entries.BPM.latest,
      // Weight
      Weight: entries.Weight.latest ? `${entries.Weight.latest.weight} ${entries.Weight.latest.weight_type}` : '-',
      Weight_raw: entries.Weight.latest,
      // Pulse
      Pulse: entries.Pulse.latest ? `${entries.Pulse.latest.pulse} BPM` : '-',
      Pulse_raw: entries.Pulse.latest ? entries.Pulse.latest : null,
      // PulseOximeter
      PulseOximeter: entries.PulseOximeter.latest ? `${entries.PulseOximeter.latest.spo2}` : '-',
      PulseOximeter_raw: entries.PulseOximeter.latest,
      // Thermometer
      Thermometer: entries.Thermometer.latest ? `${entries.Thermometer.latest.temperature} °C` : '-',
      Thermometer_raw: entries.Thermometer.latest,
      // BGM
      BGM: entries.BGM.latest ? `${entries.BGM.latest.bgm} mg/dL` : '-',
      BGM_raw: entries.BGM.latest,
    };

    // Highlight alert zones which are out of range
    const alertZones = rawPatient.alert_zones;
    Object.entries(entries).forEach(([entryType, data]) => {
      if (checkOutOfRange(alertZones, entryType, data.latest)) {
        patientInfo[entryType] = <span className="out-of-alert-zone">{patientInfo[entryType]}</span>;
      }
    });

    // Add formatting based on number of readings
    const numberOfReadings = Object.keys(TYPES_TO_ATTRIBUTES)
      .map((t) => rawPatient.entries[t].last_30_days_count)
      .reduce((partialSum, a) => partialSum + a, 0);

    // Patients with 0-7 readings marked in red
    // Patients with 8-12 readings in yellow
    // Patients with 13-16+ readings in green
    if (numberOfReadings <= 7) {
      patientInfo.number_of_readings = <strong className="red-text">{numberOfReadings}</strong>;
    } else if (numberOfReadings <= 12) {
      patientInfo.number_of_readings = <strong className="orange-text">{numberOfReadings}</strong>;
    } else if (numberOfReadings <= 15) {
      patientInfo.number_of_readings = <strong className="yellow-text text-darken-1">{numberOfReadings}</strong>;
    } else {
      patientInfo.number_of_readings = <strong className="green-text">{numberOfReadings}</strong>;
    }

    return {
      ...patientInfo,
      needs_attention: rawPatient.high_risk ? <i className="material-icons small red-text">check</i> : '',
      number_of_connected_devices: rawPatient.number_of_connected_devices,
      // Raw value for number_of_readings to allow sorting
      number_of_readings_raw: numberOfReadings,
      // Parse date in format like 2021-04-27 20:09:00 UTC
      date_added: moment(rawPatient.date_added, 'YYYY-MM-DD HH:mm:ss Z').format('MM/DD/YYYY hh:mm A'),
      // Parse date in format like 5/7/2000
      date_of_birth: (rawPatient.dob && moment(rawPatient.dob, 'M/D/YYYY').format('MM/DD/YYYY')) || '-',
      is_frozen: rawPatient.is_frozen,
    };
  });

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'First Name',
      accessor: 'first_name',
    },
    {
      Header: 'Last Name',
      accessor: 'last_name',
    },
    {
      Header: 'Chronic Conditions',
      accessor: 'chronic_conditions',
      Cell: ChronicConditions,
      disableSortBy: true,
      style: { maxWidth: '150px' },
    },
    {
      Header: 'Date of Birth',
      accessor: 'date_of_birth',
    },
    {
      Header: 'Blood Pressure',
      accessor: 'BPM',
      disableSortBy: true,
      className: 'center relative', /* This second class needed to fill all cell space ignoring paddings */
      Cell: TooltippedCellValue,
    },
    {
      Header: 'Weight',
      accessor: 'Weight',
      disableSortBy: true,
      className: 'center relative', /* The second class needed to fill all cell space ignoring paddings */
      Cell: TooltippedCellValue,
    },
    {
      Header: 'Pulse',
      accessor: 'Pulse',
      disableSortBy: true,
      className: 'center relative', /* The second class needed to fill all cell space ignoring paddings */
      Cell: TooltippedCellValue,
    },
    {
      Header: () => (
        <>
          SpO
          <sub>2</sub>
        </>
      ),
      accessor: 'PulseOximeter',
      label: 'SpO2',
      disableSortBy: true,
      className: 'center relative', /* The second class needed to fill all cell space ignoring paddings */
      Cell: TooltippedCellValue,
    },
    {
      Header: 'Temperature',
      accessor: 'Thermometer',
      disableSortBy: true,
      className: 'center relative', /* The second class needed to fill all cell space ignoring paddings */
      Cell: TooltippedCellValue,
    },
    {
      Header: 'Blood Glucose',
      accessor: 'BGM',
      disableSortBy: true,
      className: 'center relative', /* The second class needed to fill all cell space ignoring paddings */
      Cell: TooltippedCellValue,
    },
    {
      Header: '# of readings',
      accessor: 'number_of_readings',
      disableSortBy: false,
    },
    {
      Header: '# of devices',
      accessor: 'number_of_connected_devices',
      disableSortBy: true,
    },
    {
      Header: 'Needs Attention',
      accessor: 'needs_attention',
      disableSortBy: true,
    },
    {
      Header: 'Date Added',
      accessor: 'date_added',
    },
    {
      Header: 'Actions',
      Cell: PatientActions,
      disableSortBy: true,
    },
  ];

  return { columns, data, withSearch: true, defaultSortBy: 'last_name' };
};

const PatientsList = () => {
  const [state, dispatch] = React.useReducer(
    reducer,
    { data: [], isLoading: true, isError: false },
  );
  const user = useUser();

  const [filter, setFilter] = React.useState('all');
  // const [patients, setPatients] = React.useState([]);

  const handleFetchPatients = () => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('get_connected_patients', 'GET', { with_entries: true })
      .then((response) => {
        // Update list of patients
        const filteredPatients = response.connected_patients.filter((patient) => {
          if (filter === 'no-time-spent') {
            return patient.time_trackers.length === 0;
          }

          if (filter === 'out-of-range') {
            const { entries } = patient;
            const alertZones = patient.alert_zones;

            let outOfRange = false;

            Object.entries(entries).forEach(([entryType, data]) => {
              if (checkOutOfRange(alertZones, entryType, data.latest)) {
                outOfRange = true;
              }
            });

            return outOfRange;
          }

          if (filter === 'frozen') {
            return patient.is_frozen;
          }

          return true;
        });

        dispatch({
          type: 'FETCH_SUCCESS',
          payload: filteredPatients,
        });
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
      });
  };

  React.useEffect(() => {
    handleFetchPatients();
  }, [filter]);

  React.useEffect(() => {
    // Initiate tooltips
    // TODO: Refactor?
    M.Tooltip.init(document.querySelectorAll('.tooltipped'), {});
  }, [state.data]);

  return (
    <div>
      {
        user && user.practice_name && (
          <h3 className="mt4 mb4">{user.practice_name}</h3>
        )
      }

      <h4 className="header-with-button mt4 mb4">
        Patients

        {user && user.is_admin && (
          <a href="/invite-patient" className="btn btn-small white black-text right hide-on-print">Add new patient</a>
        )}
      </h4>

      <div>
        {state.isError && <p className="form-errors">{state.error}</p>}

        <UnfinishedTimersWarning patients={state.data} />

        <UnfinishedActionsWarning />

        {/* Cards */}
        <div className="row">
          <div className="col s12 m6 l4">
            <div className="card">
              <div className="card-content">
                <span className="card-title text-center">Total patients</span>
                <p className="h3 text-center">{state.data.length}</p>
              </div>
            </div>
          </div>

          <div className="col s12 m6 l4">
            <div className="card">
              <div className="card-content">
                <span className="card-title text-center">Patients with no time spent</span>
                <p className="h3 text-center">{state.data.filter((p) => p.time_trackers.length === 0).length}</p>
              </div>
            </div>
          </div>

          <div className="col s12 m6 l4">
            <div className="card">
              <div className="card-content">
                <span className="card-title text-center">Patients needs attention</span>
                <p className="h3 text-center">{state.data.filter((p) => p.high_risk).length}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="mt4 mb4">
          <button
            className={`btn black mr1 ${filter === 'all' ? 'disabled' : ''}`}
            type="button"
            onClick={() => setFilter('all')}
          >
            All Patients
          </button>
          <button
            className={`btn black mr1 ${filter === 'out-of-range' ? 'disabled' : ''}`}
            type="button"
            onClick={() => setFilter('out-of-range')}
          >
            Out-of-Range Patients
          </button>
          <button
            className={`btn black mr1 ${filter === 'no-time-spent' ? 'disabled' : ''}`}
            type="button"
            onClick={() => setFilter('no-time-spent')}
          >
            Patients With No Time Spent
          </button>

          <button
            className={`btn black mr1 ${filter === 'frozen' ? 'disabled' : ''}`}
            type="button"
            onClick={() => setFilter('frozen')}
          >
            Frozen Patients
          </button>
        </div>

        {state.isLoading ? (
          <Loader />
        ) : (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <>
            <Table {...prepareDataForTable(state.data)} centered classes="patients-table" />
          </>
        )}
      </div>

    </div>
  );
};

const UnfinishedTimersWarning = ({ patients }) => {
  const patientsWithUnfinishedTimers = patients.filter(
    (p) => p.time_trackers.length && p.time_trackers.filter((t) => t.end_time === '').length,
  );

  const patientsToShow = patientsWithUnfinishedTimers.map((p) => ({
    id: p.id,
    name: `${p.first_name} ${p.last_name}`,
  }));

  return (
    <>
      {
        patientsToShow.length > 0 && (
        <div className="form-errors">
          You have running timers for the following patients:&nbsp;
          {
            patientsToShow.map(
              (p) => <Link key={p.id} to={`/patients/${p.id}`} className="">{p.name}</Link>,
            ).reduce((prev, curr) => [prev, ', ', curr]) // Split with commas
          }
        </div>
        )
      }
    </>
  );
};

UnfinishedTimersWarning.propTypes = {
  patients: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const UnfinishedActionsWarning = () => {
  const [state, dispatch] = React.useReducer(
    reducer,
    { data: [], isLoading: true, isError: false },
  );

  const handleFetchAssignedActions = () => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('get_assigned_actions', 'GET')
      .then((response) => {
        const incompletedActions = response.actions.filter((a) => !a.completed);

        // Group by patient ID
        const patientsWithActions = incompletedActions.reduce((acc, curr) => {
          acc[curr.patient_id] = `${curr.patient_first_name} ${curr.patient_last_name}`;
          return acc;
        }, {});

        dispatch({
          type: 'FETCH_SUCCESS',
          payload: Object.entries(patientsWithActions),
        });
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
      });
  };

  React.useEffect(() => {
    handleFetchAssignedActions();
  }, []);

  return (
    <>
      {
        state.data.length > 0 && (
        <div className="form-warnings">
          You have incompleted actions for the following patients:&nbsp;
          {
            state.data.map(
              ([patientId, patientName]) => (
                <Link key={patientId} to={`/patients/${patientId}`} target="_blank">{patientName}</Link>
              ),
            ).reduce((prev, curr) => [prev, ', ', curr]) // Split with commas
          }
          &nbsp;
          <a href="/actions" target="_blank" className="btn black ml2">View all</a>
        </div>
        )
      }
    </>
  );
};

export default PatientsList;
