/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

import { useAuth } from '../utils';

const PrivateRoute = ({ component: Component, ...rest }) => {
  // Add your own authentication on the below line.
  const [logged] = useAuth();

  return (
    <Route
      {...rest}
      render={(props) => (logged ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      ))}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  location: PropTypes.instanceOf(Object),
};

PrivateRoute.defaultProps = {
  location: {},
};

export default PrivateRoute;
