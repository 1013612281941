import React from 'react';

const LoginMessage = () => (
  <section className="section">
    <h5 className="header">You must be logged in to view the patients</h5>
    <a className="btn black mt4 mr2" href="/login">Login</a>
    <a className="btn black mt4" href="/register">Register</a>
  </section>
);

export default LoginMessage;
