import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Plot from 'react-plotly.js';
import { getPlotData, reducer, sendRequest } from '../../utils';
import Loader from '../../Components/Loader';
import Table from '../../Components/Table';
import { TimerStatusContext } from '../../Context';

const getDataForTimersPlot = (timers) => {
  /*
  Helper function to get data for timers plot. This function fill empty days with 0 time spent.
  */
  const data = [];

  timers.forEach((timer, i) => {
    const startDate = moment.utc(timer.start_time, 'YYYY-MM-DD HH:mm:ss');
    const endDate = moment.utc(timer.end_time, 'YYYY-MM-DD HH:mm:ss');

    const spentTime = (endDate - startDate) / 1000 / 60; // in minutes

    data.push({
      date: startDate.startOf('day'),
      date_to_date: startDate.format('MM/DD/YYYY'),
      spent_time: spentTime,
    });

    // Generate missing data for chart
    const nextTimer = timers[i + 1];
    if (nextTimer) {
      const nextTimerStartTime = moment.utc(nextTimer.start_time, 'YYYY-MM-DD');
      let diff = nextTimerStartTime.diff(startDate.startOf('day'), 'days');

      let nextDate = startDate.clone();
      while (diff > 1) {
        nextDate = nextDate.add(1, 'days');

        data.push({
          date: nextDate.startOf('day'),
          date_to_date: nextDate.format('MM/DD/YYYY'),
          spent_time: 0,
        });

        diff -= 1;
      }
    }
  });

  return data;
};

const getPrettyTime = (totalMinutes) => {
  const diff = moment.duration(totalMinutes * 60 * 1000);

  const days = diff.days();
  const hours = (`0${days * 24 + diff.hours()}`).slice(-2);
  const minutes = (`0${diff.minutes()}`).slice(-2);
  const seconds = (`0${diff.seconds()}`).slice(-2);

  return `${hours}:${minutes}:${seconds}`;
};

const TimerTable = ({ timers }) => {
  // Table with the following columns: ID, user ID, date started, duration, date ended
  const columns = [
    {
      Header: 'Timer ID',
      accessor: 'timer_id',
      className: 'center relative',
    },
    {
      Header: 'Date Started',
      accessor: 'start_time',
      className: 'center relative',
    },
    {
      Header: 'Duration',
      accessor: 'spent_time',
      className: 'center relative',
    },
    {
      Header: 'Date Ended',
      accessor: 'end_time',
      className: 'center relative',
    },
  ];

  const data = timers.map((timer) => {
    const startTime = moment.utc(timer.start_time, 'YYYY-MM-DD HH:mm:ss');
    const endTime = moment.utc(timer.end_time, 'YYYY-MM-DD HH:mm:ss');
    return {
      timer_id: timer.id,
      start_time: startTime.local().format('MM/DD/YYYY hh:mm:ss A'),
      end_time: endTime.local().format('MM/DD/YYYY hh:mm:ss A'),
      spent_time: getPrettyTime((endTime - startTime) / 1000 / 60),
    };
  });

  return (
    <Table columns={columns} data={data} />
  );
};

TimerTable.propTypes = {
  timers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const SpentTimeSummary = ({ patientId, startDate, endDate }) => {
  const [timeSpent, setTimeSpent] = React.useState(0);
  const [state, dispatch] = React.useReducer(
    reducer,
    { data: [], isLoading: false, isError: false },
  );
  const [timers, setTimers] = React.useState([]);

  // Context to track timer status
  const timerStatus = React.useContext(TimerStatusContext);

  const fetchTimers = () => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('get_time_trackers', 'POST', { patient_id: patientId })
      .then((response) => {
        const timers = response.timetrackers.filter((t) => t.end_time !== '');

        // Filter timers by selected period
        const periodTimers = timers.filter(
          (t) => moment.utc(t.start_time, 'YYYY-MM-DD HH:mm:ss') >= startDate
            && moment.utc(t.end_time, 'YYYY-MM-DD HH:mm:ss') <= endDate,
        );

        // Add missed days to data
        const chartData = getDataForTimersPlot(periodTimers);
        const totalTimeSpent = chartData.reduce((acc, t) => acc + t.spent_time, 0);

        setTimeSpent(totalTimeSpent);
        setTimers(periodTimers);
        dispatch({ type: 'FETCH_SUCCESS', payload: chartData });
      })
      .catch(() => {
        dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' });
      });
  };

  React.useEffect(() => {
    fetchTimers();
  }, [patientId, startDate, endDate, timerStatus]);

  if (state.isLoading) {
    return <Loader />;
  }

  if (state.isError) {
    return <h5 className="text-center pt2 pb2">{state.error}</h5>;
  }

  const plotData = getPlotData({
    rawData: state.data,
    xAttr: 'date_to_date',
    yAttr: 'spent_time',
    label: 'Time Spent, minutes',
    color: '#2196f3',
    aggregate: true,
    summarize: true,
    withTrend: false,
  });

  return (
    <>
      <h5>
        Time Spent:
        {' '}
        <strong>{getPrettyTime(timeSpent)}</strong>
      </h5>

      {
        state.data.length > 0
          ? (
            <>
              <div style={{ minHeight: '450px' }}>
                <Plot
                  data={plotData}
                  style={{ width: '100%', marginBottom: '2rem' }}
                  layout={{
                    margin: {
                      l: 50, r: 50, b: 0, t: 20, pad: 0,
                    },
                    yaxis: {
                      title: 'Time spent, minutes',
                      automargin: true,
                      rangemode: 'nonnegative',
                      range: [0, Math.max(...plotData[0].y) + 10],
                    },
                    xaxis: {
                      title: 'Date',
                      automargin: true,
                      tickformat: '%m/%d/%Y',
                    },
                    autosize: true,
                    legend: {
                      x: 0, y: -0.15, orientation: 'h',
                    },
                  }}
                  config={{
                    displayModeBar: false,
                    responsive: true,
                  }}
                />
              </div>

              <TimerTable timers={timers} />
            </>
          )
          : <h5 className="text-center pt2 pb2">No data found</h5>
      }
    </>
  );
};

SpentTimeSummary.propTypes = {
  patientId: PropTypes.string.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
};

export default SpentTimeSummary;
