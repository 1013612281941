import M from '@materializecss/materialize';
import moment from 'moment';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Loader from '../Components/Loader';
import { reducer, sendRequest } from '../utils';

function AddOrEditChangeLog() {
  const { changelogId } = useParams();
  const history = useHistory();
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: {}, message: '', error: '', isLoading: false, isError: false,
    },
  );

  const onSubmit = async (e) => {
    e.preventDefault();

    dispatch({ type: 'FETCH_INIT' });

    const opts = Object.fromEntries(new FormData(e.target));

    if (changelogId) {
      sendRequest(`change_logs/${changelogId}`, 'PUT', opts)
        .then((response) => {
          if (response.status === 'Success') {
            history.push('/changelogs');
          } else {
            dispatch({ type: 'FETCH_FAILURE', error: 'No users found', payload: [] });
          }
        });
    } else {
      sendRequest('change_logs', 'POST', opts)
        .then((response) => {
          if (response.status === 'Success') {
            history.push('/changelogs');
          } else {
            dispatch({ type: 'FETCH_FAILURE', error: 'No users found', payload: [] });
          }
        });
    }
  };

  const onCancelClick = (e) => {
    e.preventDefault();
    history.goBack();
  };

  React.useEffect(() => {
    if (changelogId) {
      dispatch({ type: 'FETCH_INIT' });

      sendRequest('change_logs', 'GET', { id: changelogId })
        .then((response) => {
          if (response.status === 'Success') {
            // For now we get all change logs, so need to find only required
            const changelog = response.change_logs.find((c) => c.id === changelogId);
            dispatch({ type: 'FETCH_SUCCESS', payload: changelog });
          } else {
            dispatch({ type: 'FETCH_FAILURE', error: 'No users found', payload: [] });
          }
        });
    }
  }, [changelogId]);

  React.useEffect(() => {
    M.updateTextFields();
  });

  return (
    <>
      {
        changelogId ? (
          <h4 className="mb4">Edit Change Log</h4>
        ) : (
          <h4 className="mb4">Add a new Change Log</h4>
        )
      }

      { state.isLoading
        ? <Loader />
        : (
          <>
            <div className="row">
              <form className="col s12" onSubmit={onSubmit}>
                <div className={`form-messages ${state.message ? '' : 'hide'}`}>{state.message}</div>
                <div className={`form-errors ${state.error ? '' : 'hide'}`}>{state.error}</div>

                <div className="row">
                  <div className="input-field col s4">
                    <label htmlFor="email">Version</label>
                    <input
                      id="version"
                      name="version"
                      type="text"
                      defaultValue={state.data.version}
                      required
                      placeholder="Enter version"
                      className="validate"
                    />
                  </div>

                  <div className="input-field col s4">
                    <label htmlFor="email">Date Released</label>
                    <input
                      id="date_released"
                      name="date_released"
                      type="date"
                      defaultValue={moment(state.data.date_released, 'YYYY-MM-DD').format('YYYY-MM-DD')}
                      required
                      placeholder="Enter date released"
                      className="validate"
                    />
                  </div>

                  <div className="input-field col s4">
                    <label htmlFor="email">Module</label>
                    <input
                      id="module"
                      name="module"
                      type="text"
                      defaultValue={state.data.module}
                      required
                      placeholder="Enter affected module (i.e. Portal)"
                      className="validate"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="input-field col s12">
                    <label htmlFor="email">Change Notes</label>
                    <textarea
                      id="change_notes"
                      name="change_notes"
                      defaultValue={state.data.change_notes}
                      required
                      placeholder="Enter change notes"
                      style={{ minHeight: '200px' }}
                      className="validate materialize-textarea"
                    />
                  </div>
                </div>

                <button type="submit" className="btn black mt2">Save</button>
                <button type="button" className="btn white black-text ml2 mt2" onClick={onCancelClick}>Cancel</button>
              </form>
            </div>
          </>
        )}
    </>
  );
}

export default AddOrEditChangeLog;
