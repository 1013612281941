import M from '@materializecss/materialize';
import moment from 'moment';
import React from 'react';
import { useHistory, useParams } from 'react-router';

import Loader from '../Components/Loader';
import {
  cmToFeet, feetToCm, kgToLb, lbToKg, reducer, round, sendRequest,
} from '../utils';
import Select from '../Components/Select';

const CHRONIC_CONDITIONS = [
  ['Asthma', 'Asthma'],
  ['Hypertension', 'Hypertension'],
  ['Obesity', 'Obesity'],
  ['Diabetes', 'Diabetes'],
  ['Congestive Heart Failure (CHF)', 'Congestive Heart Failure (CHF)'],
  ['Coronary Artery Disease (CAD)', 'Coronary Artery Disease (CAD)'],
  ['Atrial Fibrillation (AFib)', 'Atrial Fibrillation (AFib)'],
  ['Chronic Kidney Disease (CKD)', 'Chronic Kidney Disease (CKD)'],
  ['Stroke', 'Stroke'],
  ['Hypothyroidism', 'Hypothyroidism'],
  ['Hyperthyroidism', 'Hyperthyroidism'],
  ['Sleep Apnea', 'Sleep Apnea'],
  ['Hypoglycemia', 'Hypoglycemia'],
  ['Chronic Pain Management', 'Chronic Pain Management'],
  ['Postoperative Care', 'Postoperative Care'],
  ['Depression and Anxiety', 'Depression and Anxiety'],
  ["Alzheimer's Disease and Dementia", "Alzheimer's Disease and Dementia"],
  ['Rheumatoid Arthritis', 'Rheumatoid Arthritis'],
  ['Osteoarthritis', 'Osteoarthritis'],
  ['Chronic Migraines', 'Chronic Migraines'],
  ['Chronic Wound Management', 'Chronic Wound Management'],
  ['Preeclampsia', 'Preeclampsia'],
  ['Postpartum preeclampsia', 'Postpartum preeclampsia'],
  ['Infectious Diseases', 'Infectious Diseases'],
  ['Gastrointestinal Disorders', 'Gastrointestinal Disorders'],
  ['Autoimmune Disorders', 'Autoimmune Disorders'],
  ['Substance Use Disorder Management', 'Substance Use Disorder Management'],
  ['Geriatrics', 'Geriatrics'],
  ['Post-transplant Care', 'Post-transplant Care'],
  ['Cardiac Arrhythmias', 'Cardiac Arrhythmias'],
  ['Peripheral Artery Disease (PAD)', 'Peripheral Artery Disease (PAD)'],
  ['Cystic Fibrosis', 'Cystic Fibrosis'],
  ['Sickle Cell Disease', 'Sickle Cell Disease'],
  ['Organ Transplant Recipients', 'Organ Transplant Recipients'],
  ['Palliative Care', 'Palliative Care']];

const PatientInfoEdit = () => {
  const { patientId } = useParams();
  const history = useHistory();
  const [units, setUnits] = React.useState('imperial');
  const [height1, setHeight1] = React.useState(0);
  const [height2, setHeight2] = React.useState(0);
  const [weight, setWeight] = React.useState(0);
  const [chronicConditions, setChronicConditions] = React.useState([]);

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: {}, message: '', error: '', isLoading: false, isError: false,
    },
  );

  const handleFetchPatient = React.useCallback(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('get_user_by_id', 'POST', { id: patientId })
      .then((response) => {
        dispatch({
          type: 'FETCH_SUCCESS',
          payload: response.data,
        });

        // Set units
        const weight = round(response.data.weight, 1);
        const height = round(response.data.height, 2);

        if (response.data.height_type === 'cm') {
          setUnits('metric');
          setWeight(weight);
          setHeight1(height);
        } else {
          setUnits('imperial');

          const [height1, height2] = String(height).split('.');

          setWeight(weight);
          setHeight1(parseInt(height1, 10));
          setHeight2(parseInt(height2, 10));
        }

        // Set chronic conditions
        setChronicConditions(response.data.chronic_conditions || []);

        // Initialize form fields
        M.AutoInit();
        M.updateTextFields();
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
      });
  }, [patientId]);

  React.useEffect(() => {
    handleFetchPatient();
  }, [handleFetchPatient]);

  React.useEffect(() => {
    M.updateTextFields();

    // Resize <textarea> elements
    setTimeout(
      () => {
        document.querySelectorAll('textarea').forEach(
          (textarea) => M.textareaAutoResize(textarea),
        );
      }, 250,
    );
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch({ type: 'FETCH_INIT' });

    let opts = Object.fromEntries(new FormData(e.target));

    opts = {
      ...opts,
      id: patientId,
      // This field will be missed if not checked
      high_risk: !!opts.high_risk,
      chronic_conditions: chronicConditions,
      // Format date of birth to MM/DD/YYYY
      dob_date: moment(opts.dob_date).format('MM/DD/YYYY'),
    };

    sendRequest('update_user_by_id', 'PUT', opts)
      .then((response) => {
        if (response.status === 'Failure') {
          dispatch({ type: 'FETCH_FAILURE', error: response.errors });
        } else if (e.nativeEvent.submitter.value === 'submit-and-go-back') {
          history.goBack();
        } else {
          // Show success message and update state from form fields
          dispatch({ type: 'FETCH_SUCCESS', message: response.message, payload: { ...state, ...opts } });
        }
      })
      .catch(() => {
        dispatch({ type: 'FETCH_FAILURE' });
      });
  };

  const onSwitchUnits = (e) => {
    e.preventDefault();

    if (units === 'metric') {
      setUnits('imperial');

      setWeight(kgToLb(weight));

      const [feets, inches] = cmToFeet(height1);

      setHeight1(feets);
      setHeight2(inches);
    } else {
      setUnits('metric');

      setWeight(lbToKg(weight));
      setHeight1(feetToCm(height1, height2));
    }

    setTimeout(M.updateTextFields, 1);
  };

  const handleChronicConditionsChange = (e) => {
    console.log(e.target.value);

    // This is multiple select, so we need to convert it to array
    const selectedOptions = Array.from(e.target.options).filter((option) => option.selected);
    const selectedValues = selectedOptions.map((option) => option.value);

    setChronicConditions(selectedValues);
  };

  return (
    <>
      <div className="mt4">
        <h5 className="header mb4">
          Edit Patient Info
        </h5>
        {
          state.isLoading
            ? <Loader />
            : (
              <div className="row">
                <form className="col s12" onSubmit={handleSubmit}>
                  <div className={`form-messages ${state.message ? '' : 'hide'}`}>{state.message}</div>
                  <div className={`form-errors ${state.isError ? '' : 'hide'}`}>{state.error}</div>

                  <div className="row">
                    <div className="input-field col s12 m6">
                      <label htmlFor="first_name">First Name</label>
                      <input
                        id="first_name"
                        name="first_name"
                        type="text"
                        className="validate"
                        required
                        defaultValue={state.data.first_name}
                      />
                    </div>
                    <div className="input-field col s12 m6">
                      <label htmlFor="last_name">Last Name</label>
                      <input
                        id="last_name"
                        name="last_name"
                        type="text"
                        className="validate"
                        required
                        defaultValue={state.data.last_name}
                      />
                    </div>
                    <div className="input-field col s12 m6">
                      <label htmlFor="phone_number">Phone Number</label>
                      <input
                        id="phone_number"
                        name="phone_number"
                        type="tel"
                        defaultValue={state.data.phone_number}
                      />
                    </div>
                    <div className="input-field col s12 m6">
                      <label htmlFor="email">Email</label>
                      <input
                        id="email"
                        name="email"
                        type="email"
                        defaultValue={state.data.email}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="input-field col s12">
                      <select defaultValue={state.data.gender} name="gender">
                        <option disabled>
                          Choose gender
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                      <label htmlFor="gender">Gender</label>
                    </div>
                  </div>

                  <div className="row">
                    <div className="input-field col s12">
                      <input
                        id="dob_date"
                        name="dob_date"
                        type="date"
                        className="validate"
                        defaultValue={moment(state.data.dob_date, 'MM/DD/YYYY').format('YYYY-MM-DD')}
                      />
                      <label htmlFor="password">Date Of Birth</label>
                    </div>
                  </div>

                  {
                    units === 'metric'
                      ? (
                        <>
                          <div className="row">
                            <div className="input-field col s12 m6">
                              <label htmlFor="height">Height, cm</label>
                              <input
                                id="height"
                                name="height"
                                type="number"
                                step="1"
                                className="validate"
                                value={height1}
                                onChange={(e) => setHeight1(e.target.value)}
                              />
                            </div>
                            <div className="input-field col s12 m6">
                              <label htmlFor="weight">Weight, kg</label>
                              <input
                                id="weight"
                                name="weight"
                                type="number"
                                step="0.01"
                                className="validate"
                                value={weight}
                                onChange={(e) => setWeight(e.target.value)}
                              />
                            </div>
                          </div>
                          <input id="height_type" name="height_type" type="hidden" value="cm" />
                          <input id="weight_type" name="weight_type" type="hidden" value="kg" />
                          <a href="#/" className="btn-flat mb2" onClick={onSwitchUnits}>Go Back to ft/lbs</a>
                        </>
                      )
                      : (
                        <>
                          <div className="row">
                            <div className="input-field col s12 m3">
                              <label htmlFor="weight">Height, ft.</label>
                              <input
                                id="height_ft"
                                name="height_ft"
                                type="number"
                                step="1"
                                className="validate"
                                value={height1}
                                onChange={(e) => setHeight1(e.target.value)}
                              />
                            </div>
                            <div className="input-field col s12 m3">
                              <label htmlFor="height">Height, in.</label>
                              <input
                                id="height_in"
                                name="height_in"
                                type="number"
                                step="1"
                                max="12"
                                className="validate"
                                value={height2}
                                onChange={(e) => setHeight2(e.target.value)}
                              />
                            </div>
                            <div className="input-field col s12 m6">
                              <label htmlFor="weight">Weight, lbs</label>
                              <input
                                id="weight"
                                name="weight"
                                type="number"
                                step="0.01"
                                className="validate"
                                value={weight}
                                onChange={(e) => setWeight(e.target.value)}
                              />
                            </div>
                          </div>
                          <input id="height_type" name="height_type" type="hidden" value="feet" />
                          <input id="weight_type" name="weight_type" type="hidden" value="lbs" />
                          <a href="#/" className="btn-flat mb2" onClick={onSwitchUnits}>I would rather use cm/kg</a>
                        </>
                      )
                  }

                  <div className="row">
                    <div className="input-field col s12">
                      <Select
                        name="chronic_conditions"
                        label="Chronic Conditions"
                        values={CHRONIC_CONDITIONS}
                        defaultValue={state.data.chronic_conditions || []}
                        onChange={handleChronicConditionsChange}
                        multiple
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="input-field col s12">
                      <div className="left mr4">
                        <label className="font-1" htmlFor="high_risk">
                          High Risk
                          <input type="hidden" />
                        </label>
                      </div>
                      <div className="switch high-risk-switch left">
                        <label htmlFor="high_risk">
                          No
                          <input
                            id="high_risk"
                            name="high_risk"
                            type="checkbox"
                            defaultChecked={state.data.high_risk}
                          />
                          <span className="lever" />
                          Yes
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="input-field col s12">
                      <textarea
                        id="medications"
                        name="medications"
                        className="materialize-textarea validate"
                        defaultValue={state.data.medications}
                      />
                      <label htmlFor="medications">Medications</label>
                    </div>
                  </div>

                  <div className="row">
                    <div className="input-field col s12">
                      <textarea
                        id="conditions"
                        name="conditions"
                        className="materialize-textarea validate"
                        defaultValue={state.data.conditions}
                      />
                      <label htmlFor="medications">Conditions</label>
                    </div>
                  </div>

                  <div className="row">
                    <div className="input-field col s12">
                      <textarea
                        id="treatments"
                        name="treatments"
                        className="materialize-textarea validate"
                        defaultValue={state.data.treatments}
                      />
                      <label htmlFor="medications">Surgeries / Treatments</label>
                    </div>
                  </div>

                  <div className="row">
                    <div className="input-field col s12">
                      <input
                        id="mio_connect_bpm_id"
                        name="mio_connect_bpm_id"
                        type="text"
                        className="validate"
                        defaultValue={state.data.mio_connect_bpm_id}
                      />
                      <label htmlFor="mio_connect_bpm_id">Mio BPM Device ID</label>
                    </div>

                    <div className="input-field col s12">
                      <input
                        id="mio_connect_scale_id"
                        name="mio_connect_scale_id"
                        type="text"
                        className="validate"
                        defaultValue={state.data.mio_connect_scale_id}
                      />
                      <label htmlFor="mio_connect_scale_id">Mio Scale Device ID</label>
                    </div>
                  </div>

                  <div className="row">
                    <h6>Emergency Contact</h6>

                    <div className="input-field col s12 m6">
                      <input
                        id="contact_name"
                        name="contact_name"
                        type="text"
                        className="validate"
                        defaultValue={state.data.contact_name}
                      />
                      <label htmlFor="contact_name">Name</label>
                    </div>

                    <div className="input-field col s12 m6">
                      <input
                        id="contact_phone_number"
                        name="contact_phone_number"
                        type="tel"
                        className="validate"
                        defaultValue={state.data.contact_phone_number}
                      />
                      <label htmlFor="contact_phone_number">Phone</label>
                    </div>

                    <div className="input-field col s12 m6">
                      <input
                        id="contact_email"
                        name="contact_email"
                        type="email"
                        className="validate"
                        defaultValue={state.data.contact_email}
                      />
                      <label htmlFor="contact_email">Email</label>
                    </div>

                    <div className="input-field col s12 m6">
                      <input
                        id="contact_relation"
                        name="contact_relation"
                        type="text"
                        className="validate"
                        defaultValue={state.data.contact_relation}
                      />
                      <label htmlFor="contact_relation">Relation</label>
                    </div>

                  </div>

                  <button type="submit" value="submit-and-go-back" className="btn black mt2 ml2">Save</button>
                  <button
                    type="button"
                    value="close"
                    className="btn white black-text mt2 ml2"
                    onClick={() => history.goBack()}
                  >
                    Close
                  </button>
                </form>
              </div>
            )
        }
      </div>
    </>
  );
};

export default PatientInfoEdit;
