import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Table from '../Components/Table';
import { reducer, sendRequest } from '../utils';

const ColleaguesList = () => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isLoading: true, isError: false,
    },
  );

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    // Load list of colleagues
    sendRequest('get_full_colleague_connections', 'GET')
      .then((resp) => {
        dispatch({
          type: 'FETCH_SUCCESS',
          payload: resp.colleague_connections,
        });
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
      });
  }, []);

  const handleDelete = (colleagueId) => {
    sendRequest('delete_colleague_connection', 'DELETE', { id: colleagueId })
      .then((response) => {
        if (response.status === 'Success') {
          const newPayload = state.data.filter((colleague) => colleague.id !== colleagueId);
          dispatch({ type: 'FETCH_SUCCESS', message: response.message, payload: newPayload });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.errors });
        }
      });
  };

  return (
    <>
      <h4 className="mt4 mb4">Colleagues</h4>
      <Link to="/colleagues/add" className="btn black mb2">Add a new colleague</Link>

      {state.isError && <p className="form-errors">{state.error}</p>}
      {state.message && <p className="form-messages">{state.message}</p>}

      {state.data.length
        ? (
          <ColleaguesTable
            colleagues={state.data}
            actions={[
              {
                label: 'Delete',
                callback: handleDelete,
              },
            ]}
          />
        )
        : <p>You have no added colleagues</p>}
    </>
  );
};

const ColleaguesTable = ({ colleagues, actions }) => {
  const token = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY'));
  const ColleagueActions = ({ row: { original: cell } }) => (
    <div>
      {actions.map((action) => {
        if (cell.user_id === token.id) {
          return (
            <a
              key={action.label}
              className={`btn mr2 mb2 ${action.color || 'black'}`}
              href="#/"
              onClick={() => action.callback(cell.id)}
            >
              {action.label}
            </a>
          );
        }
        return (<label key={action.label}>-</label>);
      })}
    </div>
  );

  ColleagueActions.propTypes = {
    row: PropTypes.shape({
      original: PropTypes.shape({
        id: PropTypes.string,
      }),
    }).isRequired,
  };

  const columns = [
    {
      Header: 'First Name',
      accessor: 'colleague_first_name',
    },
    {
      Header: 'Last Name',
      accessor: 'colleague_last_name',
    },
    {
      Header: 'Actions',
      Cell: ColleagueActions,
      disableSortBy: true,
    },
  ];

  return (
    <Table columns={columns} data={colleagues} centered />
  );
};

ColleaguesTable.propTypes = {
  colleagues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      colleague_first_name: PropTypes.string.isRequired,
      colleague_last_name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      callback: PropTypes.func.isRequired,
      color: PropTypes.string,
    }),
  ).isRequired,
};

export default ColleaguesList;
