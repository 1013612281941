import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { reducer, sendRequest } from '../utils';

function updateTimeSpent(serverStartTime, timeDiff) {
  /*
  Returns time spent in milliseconds
  */
  const currentDatetime = moment.utc();
  const startDatetime = moment.utc(serverStartTime, 'YYYY-MM-DD h:mm:ss');

  const diff = currentDatetime.diff(startDatetime);
  const duration = moment.duration(diff).add(timeDiff);

  return duration.asMilliseconds();
}

function generateTimeString(milliseconds) {
  /*
  Returns a string in the format of 'HH:MM:SS'
  */
  const duration = moment.duration(milliseconds);

  const days = duration.days();
  const hours = (`0${days * 24 + duration.hours()}`).slice(-2);
  const minutes = (`0${duration.minutes()}`).slice(-2);
  const seconds = (`0${duration.seconds()}`).slice(-2);

  return `${hours}:${minutes}:${seconds}`;
}

function showTimerAlert(milliseconds) {
  // In some cases difference between start_time and current time can be less than 1 second
  // This is why we need to add a bit hacky additional check for 1000 milliseconds
  if (milliseconds < 1000) {
    return false;
  }

  // Show timer alert each 5 minutes
  return (Math.round(milliseconds / 1000) % (60 * 5)) === 0;
}

const Timer = ({ onUpdate }) => {
  const { id } = useParams();

  const [timeSpent, setTimeSpent] = React.useState(null);

  // This state used to avoid issues with wrong time on the client side
  const [timeDiff, setTimeDiff] = React.useState(moment.duration(0));

  const [state, dispatch] = React.useReducer(
    reducer,
    { data: {}, isLoading: true, isError: false },
  );

  const handleStartTimer = (e) => {
    e.preventDefault();

    dispatch({ type: 'FETCH_INIT' });

    sendRequest('start_time_tracker', 'POST', { patient_id: id }).then((response) => {
      // Fetch new timer
      sendRequest('get_time_tracker', 'POST', { id: response.id }).then((response) => {
        dispatch({
          type: 'FETCH_SUCCESS',
          payload: response.timetracker,
        });
      });

      // Update status
      onUpdate(true);
    });
  };

  const handleStopTimer = (e) => {
    e.preventDefault();

    dispatch({ type: 'FETCH_INIT' });

    sendRequest('end_time_tracker', 'POST', { id: state.data.id }).then(() => {
      // When timer stops, clean up the state
      setTimeSpent(null);
      dispatch({
        type: 'FETCH_SUCCESS',
        payload: {},
      });

      // Update status
      onUpdate(false);
    });
  };

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('get_time_trackers', 'POST', { patient_id: id }).then((response) => {
      const timer = response.timetrackers.filter((t) => t.end_time === '')[0];

      // Save time diff between server and client
      const serverTime = moment.utc(response.server_time, 'YYYY-MM-DD h:mm:ss').milliseconds(0);
      const newTimeDiff = moment.duration(serverTime.diff(moment.utc().milliseconds(0)));
      setTimeDiff(newTimeDiff);

      if (timer) {
        sendRequest('get_time_tracker', 'POST', { id: timer.id }).then((response) => {
          dispatch({
            type: 'FETCH_SUCCESS',
            payload: response.timetracker,
          });
        });
      } else {
        dispatch({ type: 'FETCH_SUCCESS', payload: {} });

        // setTimeout(() => {
        // // Ask user to start timer
        //   if (window.confirm('Timer for patient is not running. Do you want to start the timer?')) {
        //     handleStartTimer(new Event('click')); // Simulate event
        //   }
        // }, 500);
      }

      // Found running timer, notify the parent component
      onUpdate(!!timer);
    });
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      if (state.data.start_time) {
        const newTimeSpent = updateTimeSpent(state.data.start_time, timeDiff);

        setTimeSpent(newTimeSpent);

        if (showTimerAlert(newTimeSpent)) {
          const response = window.confirm('Timer for patient still running. Do you want to stop the timer?');
          if (response) {
            // Stop the timer
            handleStopTimer(new Event('click')); // Simulate event
          }
        }
      } else {
        setTimeSpent(null);
      }
    }, 500);
  });

  if (state.isLoading) {
    // Show nothing while loading
    return '';
  }

  return (
    <>
      <span className="grey-text timer-container">
        {
          state.data.id
            ? (
              <>
                <span>{generateTimeString(timeSpent)}</span>
                <a className="btn waves-effect waves-light red white-text ml1 timer-button" href="#/" onClick={handleStopTimer}>
                  <i className="material-icons right">stop</i>
                  Stop timer
                </a>
              </>
            )
            : (
              <a className="btn waves-effect waves-light green white-text ml1 timer-button" href="#/" onClick={handleStartTimer}>
                <i className="material-icons right">play_arrow</i>
                Start timer
              </a>
            )
        }
      </span>
    </>
  );
};

Timer.propTypes = {
  onUpdate: PropTypes.func.isRequired,
};

export default Timer;
