import M from '@materializecss/materialize';
import React from 'react';
import { Prompt, useHistory, useLocation, useParams } from 'react-router';
import PropTypes from 'prop-types';

import AlertZones from './Settings/AlertZones/List';
import CarePlan from './CarePlan/List';
import CommonInfo from './CommonInfo';
import Loader from '../Components/Loader';
import LoginMessage from '../Components/LoginMessage';
import PatientDetails from './Details';
import Summary from './Summary/Summary';
import Timer from './Timer';

import { TimerStatusContext } from '../Context';
import { reducer, sendRequest, useAuth } from '../utils';

const Patient = () => {
  const [logged] = useAuth();
  const { id } = useParams();
  const history = useHistory();

  // This state variable is used to prevent the user from navigating away from the page
  const [timerStatus, setTimerStatus] = React.useState(false);
  // This state variable is used to store current page path
  const [locationPath, setLocationPath] = React.useState(window.location.pathname);

  const [state, dispatch] = React.useReducer(
    reducer,
    { data: {}, isLoading: true, isError: false },
  );

  const handleFetchPatient = React.useCallback(async () => {
    dispatch({ type: 'FETCH_INIT' });

    if (logged) {
      sendRequest('get_user_by_id', 'POST', { id })
        .then((response) => {
          if (response.status === 'Failure') {
            dispatch({ type: 'FETCH_FAILURE', error: response.errors || response.message });
          } else {
            dispatch({
              type: 'FETCH_SUCCESS',
              payload: response.data,
            });
          }
        })
        .catch((error) => {
          dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
        });
    }
  }, [id, logged]);

  React.useEffect(() => {
    handleFetchPatient();
  }, [handleFetchPatient]);

  React.useEffect(() => {
    if (state.data.full_name) {
      document.title = `Patients - ${state.data.full_name}`;
      return;
    }
    document.title = 'Patients - Loading...';
  }, [state.data]);

  const generatePrompt = React.useCallback((location) => {
    if (!location.pathname.startsWith(locationPath)) {
      return "Please note you are still tracking time for this patient. Please don't forget to stop the timer";
    }

    return true;
  }, []);

  React.useEffect(() => history.listen((location) => {
    setLocationPath(location.pathname);
  }), []);

  const handleTimerUpdate = React.useCallback((status) => {
    setTimerStatus(status);
  }, []);

  return (
    <TimerStatusContext.Provider value={timerStatus}>
      <div>
        {!logged
          ? <LoginMessage />
          : (
            <div>
              {state.isError && <p>Something went wrong ...</p>}

              {state.isLoading ? (
                <Loader />
              ) : (
                <>
                  <Prompt
                    when={timerStatus}
                    message={generatePrompt}
                  />
                  <div>
                    <h4 className="mt2 mb2 d-inline-block pr2">
                      Patient:
                      <strong>{` ${state.data.first_name} ${state.data.last_name}`}</strong>
                    </h4>
                    <Timer onUpdate={handleTimerUpdate} />
                  </div>
                  <PatientInfo patient={state.data} />
                </>
              )}
            </div>
          )}
      </div>
    </TimerStatusContext.Provider>
  );
};

const PatientInfo = ({ patient }) => {
  const history = useHistory();
  const { hash } = useLocation();

  // Used to reduce number of requests when user opens patient page
  const [dataTabActive, setDataTabActive] = React.useState(hash === '#data');

  React.useEffect(() => {
    // Use small delay because React.js need some time to render tabs
    setTimeout(() => M.Tabs.init(
      document.querySelector('.patient-tabs'),
      {
        onShow: (tab) => {
          history.push({ hash: tab.getAttribute('id') });
        },
      },
    ), 100);
  });

  return (
    <>
      <div className="row">
        <div className="col s12">
          <ul className="tabs tabs-fixed-width patient-tabs">
            <li className="tab col s3"><a className="active" href="#summary">Summary</a></li>
            <li className="tab col s3"><a href="#care-plan">Care Plan</a></li>
            <li className="tab col s3"><a href="#info">Info</a></li>
            <li className="tab col s3"><a href="#data" onClick={() => setDataTabActive(true)}>Data</a></li>
            <li className="tab col s3"><a href="#settings">Settings</a></li>
          </ul>
        </div>
        <div id="summary" className="col s12 pt2">
          <Summary patient={patient} />
        </div>
        <div id="care-plan" className="col s12">
          <CarePlan patient={patient} />
        </div>
        <div id="info" className="col s12">
          <CommonInfo patient={patient} />
        </div>
        <div id="data" className="col s12 pt2">
          <PatientDetails patient={patient} active={dataTabActive} />
        </div>
        <div id="settings" className="col s12">
          <AlertZones patient={patient} />
        </div>
      </div>
    </>
  );
};

PatientInfo.propTypes = {
  patient: PropTypes.shape({
    id: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    weight: PropTypes.string.isRequired,
    weight_type: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    height_type: PropTypes.string.isRequired,
    high_risk: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    dob_date: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
  }).isRequired,
};

export default Patient;
