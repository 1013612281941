import React from 'react';
import PropTypes from 'prop-types';

const TimeSelector = ({ defaultTimes, onChange }) => {
  // This component allow to select as many times as needed
  const [times, setTimes] = React.useState([]);

  React.useEffect(() => {
    setTimes(defaultTimes);
  }, [defaultTimes]);

  const handleAddTime = (e) => {
    const time = e.target.value;

    if (!time || times.includes(time)) {
      return;
    }

    const newTimes = [...times, time].sort((a, b) => {
      const aTime = new Date(`01/01/2007 ${a}`);
      const bTime = new Date(`01/01/2007 ${b}`);

      return aTime - bTime;
    });

    setTimes(newTimes);

    // Reset timepicker
    e.target.value = '';

    // Send new times to parent component
    onChange(newTimes);
  };

  const handleRemoveTime = (e) => {
    e.preventDefault();

    const time = e.target.previousSibling.textContent;

    setTimes((prevTimes) => prevTimes.filter((t) => t !== time));
  };

  return (
    <div className="row">
      {
        times.map((time) => (
          <span className="badge black-text border left time-badge" key={time}>
            {time}
            <a href="/#" className="ml1 black-text" onClick={handleRemoveTime}>X</a>
          </span>
        ))
      }

      <div className="input-field col s12 m12">
        <label htmlFor="reminder_time">Time</label>
        <input type="text" name="reminder_time" className="timepicker" onSelect={handleAddTime} />
      </div>
    </div>
  );
};

TimeSelector.propTypes = {
  defaultTimes: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
};

TimeSelector.defaultProps = {
  defaultTimes: [],
};

export default TimeSelector;
