import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import Table from '../Components/Table';

import { reducer, sendRequest } from '../utils';

const CreatedAt = ({ row: { original: cell } }) => (
  <div>{moment.utc(cell.updated_at, 'YYYY-MM-DD h:mm:ss').local().format('LLL')}</div>
);

const correctDeviceType = (type) => {
  if (type === 'Weight') {
    return 'Scale';
  }
  return type;
};

const correctDeviceName = (cell) => {
  if (cell.device_original_name) {
    return cell.device_original_name;
  }
  return cell.device_name;
};

CreatedAt.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string,
      updated_at: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

const ConnectedDevices = ({ patientId }) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', reload: false, isLoading: false, isError: false,
    },
  );

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('get_connected_devices', 'GET', { patient_id: patientId })
      .then((resp) => {
        const devices = resp.data;

        dispatch({
          type: 'FETCH_SUCCESS',
          payload: devices,
          reload: false,
        });
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
      });
  }, [state.reload]);

  const DeviceId = ({ row: { original: cell } }) => (
    <div>{cell.device_id}</div>
  );
  const DeviceName = ({ row: { original: cell } }) => (
    <div>{correctDeviceName(cell)}</div>
  );
  const DeviceModel = ({ row: { original: cell } }) => (
    <div>{cell.device_model === '' ? correctDeviceType(cell.device_type) : `${correctDeviceType(cell.device_type)} - ${cell.device_model}`}</div>
  );

  DeviceId.propTypes = {
    row: PropTypes.shape({
      original: PropTypes.shape({
        id: PropTypes.string,
        device_id: PropTypes.string.isRequired,
      }),
    }).isRequired,
  };
  DeviceName.propTypes = {
    row: PropTypes.shape({
      original: PropTypes.shape({
        id: PropTypes.string,
        device_name: PropTypes.string.isRequired,
        device_original_name: PropTypes.string.isRequired,
      }),
    }).isRequired,
  };
  DeviceModel.propTypes = {
    row: PropTypes.shape({
      original: PropTypes.shape({
        id: PropTypes.string,
        device_type: PropTypes.string.isRequired,
        device_model: PropTypes.string,
      }),
    }).isRequired,
  };
  const columns = [
    {
      Header: 'Device Id',
      accessor: 'device_id',
      Cell: DeviceId,
    },
    {
      Header: 'Device Name',
      accessor: 'device_name',
      Cell: DeviceName,
    },
    {
      Header: 'Device Model',
      accessor: 'device_model',
      Cell: DeviceModel,
    },
    {
      Header: 'Created On',
      Cell: CreatedAt,
      accessor: 'updated_at',
    },
  ];

  return (
    <>
      <h5 className="mt4 mb4">Connected Devices</h5>
      {state.data.length > 0
        ? (
          <Table columns={columns} data={state.data} centered defaultPageSize={10} />
        )
        : (
          <p className="form-messages">No devices found</p>
        )}
    </>
  );
};

ConnectedDevices.propTypes = {
  patientId: PropTypes.string.isRequired,
};

export default ConnectedDevices;
