import React from 'react';

import M from '@materializecss/materialize';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { reducer, sendRequest } from '../../../utils';
import Loader from '../../../Components/Loader';
import Select from '../../../Components/Select';
import TimeSelector from './TimeSelector';

const DAYS = {
  // Every: 'Every',
  Sunday: 'Sunday',
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
};

const TYPES = {
  'Blood Pressure': 'Blood Pressure',
  ECG: 'Pulse',
  Weight: 'Weight',
  'Pulse Oximeter': 'Pulse Oximeter',
  Thermometer: 'Temperature',
  BGM: 'Blood Glucose',
};

const EditReminder = () => {
  const { patientId, ids } = useParams();
  const history = useHistory();

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isError: false, isLoading: false,
    },
  );
  const [times, setTimes] = React.useState([]); // ['10:00 AM', '11:00 AM']

  const fetchReminders = async (ids) => {
    dispatch({ type: 'FETCH_INIT' });

    Promise.all(ids.map((id) => sendRequest('physician/get_reminder_by_id', 'GET', { id })))
      .then((responses) => {
        if (responses.some((response) => response.status !== 'Success')) {
          dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' });
          return;
        }

        const reminders = responses.map((response) => response.reminder);

        // Validate that all reminders should have the same entry_type and reminder_days
        const isValid = reminders.every((reminder) => reminder.entry_type === reminders[0].entry_type
            && reminder.reminder_days === reminders[0].reminder_days);

        if (!isValid) {
          dispatch({
            type: 'FETCH_FAILURE',
            error: 'Bad reminder ID: all reminders should have the same entry_type and reminder_days',
          });
          return;
        }

        // Merge all reminders into one
        const reminder = reminders.reduce((acc, reminder) => {
          acc.id = `${acc.id}-${reminder.id}`; // `1-2-3
          acc.ids.push(reminder.id);
          acc.reminder_times.push(
            // Convert UTC time to local time
            moment.utc(reminder.reminder_time, 'h:mm:ss').local().format('h:mm A'),
          );

          return acc;
        }, {
          ...reminders[0],
          id: reminders[0].id,
          ids: [],
          reminder_times: [],
        });

        dispatch({ type: 'FETCH_SUCCESS', payload: reminder });
        setTimes(reminder.reminder_times);
      })
      .catch(() => {
        dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' });
      });
  };

  React.useEffect(() => {
    if (ids) {
      fetchReminders(ids.split('-'));
    }
  }, []);

  const handleTimesChange = (times) => {
    setTimes(times);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // dispatch({ type: 'FETCH_INIT' });

    const opts = Object.fromEntries(new FormData(e.target));

    const daysSelect = e.target.querySelector('select[name="reminder_days"]');
    const typesSelect = e.target.querySelector('select[name="entry_type"]');

    const days = M.FormSelect.getInstance(daysSelect).getSelectedValues();
    const types = M.FormSelect.getInstance(typesSelect).getSelectedValues();

    if (!days.length || !types.length) {
      dispatch({ type: 'FETCH_FAILURE', error: 'Please select at least one day and type' });
      return;
    }

    opts.reminder_days = days.join(', ');
    opts.entry_type = types.join(', ');
    opts.reminder_status = 'Active';

    const splitIds = ids.split('-');

    if (splitIds.length > times.length) {
      // Remove extra reminders
      Promise.all(splitIds.slice(times.length).map(
        (id) => sendRequest('physician/delete_reminder', 'DELETE', { id }),
      ));
    }

    // Update existing reminders
    Promise.all(times.map((time, index) => {
      // Time should be in UTC
      opts.reminder_time = moment(time, 'h:m a').utc().format('H:mm:ss');

      const reminderId = splitIds[index];
      if (reminderId) {
        opts.id = reminderId;
        return sendRequest('physician/update_reminder', 'PUT', opts);
      }

      // Add new reminder
      opts.user_id = patientId;
      opts.reminder_status = 'Active';
      return sendRequest('physician/add_reminder', 'POST', opts);
    }))
      .then((responses) => {
        const errors = responses.filter((r) => r.status !== 'Success').map((r) => r.errors);
        if (errors.length) {
          dispatch({ type: 'FETCH_FAILURE', error: errors.join(', ') });
        } else {
          // Go back to the previous page
          history.goBack();
        }
      })
      .catch(() => dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' }));
  };

  React.useEffect(() => {
    M.AutoInit();
    M.updateTextFields();
  });

  const parseMultipleSelectValues = (string) => {
    if (string) {
      return string.split(',').map((i) => i.trim());
    }
    return [];
  };

  return (
    <>
      <h5 className="header mt4">
        Edit Reminder
      </h5>

      {
        state.isLoading
          ? <Loader />
          : (
            <div className="row">
              <form className="col s12" onSubmit={handleSubmit}>
                <div className={`form-messages ${state.message ? '' : 'hide'}`}>{state.message}</div>
                <div className={`form-errors ${state.isError ? '' : 'hide'}`}>{state.error}</div>

                <TimeSelector defaultTimes={times} onChange={handleTimesChange} />

                <div className="row">
                  <div className="input-field col s12 m12">
                    <Select
                      name="reminder_days"
                      label="Days"
                      values={DAYS}
                      defaultValue={parseMultipleSelectValues(state.data.reminder_days)}
                      multiple
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="input-field col s12 m12">
                    <Select
                      name="entry_type"
                      label="Types"
                      values={TYPES}
                      defaultValue={parseMultipleSelectValues(state.data.entry_type)}
                      multiple
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="input-field col s12 m12">
                    <textarea name="reminder_notes" className="materialize-textarea" defaultValue={state.data.reminder_notes} />
                    <label htmlFor="reminder_notes">Notes</label>
                  </div>
                </div>

                <input type="hidden" name="id" defaultValue={state.data.id} />

                <button type="submit" className="btn black mt2 ml2">Save And Close</button>
                <button
                  type="button"
                  value="close"
                  className="btn white black-text mt2 ml2"
                  onClick={() => history.goBack()}
                >
                  Close
                </button>
              </form>
            </div>
          )
      }
    </>
  );
};

EditReminder.propTypes = {};

export default EditReminder;
