import React from 'react';
import PropTypes from 'prop-types';
import M from '@materializecss/materialize';

import { useHistory } from 'react-router-dom';
import { cmToFeet, kgToLb, reducer, sendRequest } from '../utils';

import ConnectedDevices from './ConnectedDevices';

function calculateImperialBMI(patient) {
  // Weight should be in lbs
  let weight = parseFloat(patient.weight);
  let height = 0;

  if (patient.weight_type === 'kg') {
    weight = kgToLb(weight);
  }

  // Height should be in inches
  if (patient.height_type === 'feet') {
    const ft = parseInt(patient.height.split('.')[0], 10);
    const inch = parseInt(patient.height.split('.')[1], 10);
    height = ft * 12 + inch;
  } else {
    // Convert height from cm to inches
    height = parseFloat(patient.height);
    const [ft, inch] = cmToFeet(height);
    height = ft * 12 + inch;
  }

  const BMI = (weight / (height * height)) * 703;
  let roundedBMI = Math.floor(BMI);
  let diff = Math.round((BMI - roundedBMI) * 10);

  if (diff === 10) {
    roundedBMI += 1;
    diff = 0;
  }

  return `${roundedBMI}.${diff}`;
}

function calculateMetricBMI(patient) {
  const weight = parseFloat(patient.weight);
  const height = parseFloat(patient.height) / 100;

  const BMI = weight / (height * height);

  let roundedBMI = Math.floor(BMI);

  let diff = Math.round((BMI - roundedBMI) * 10);

  if (diff === 10) {
    roundedBMI += 1;
    diff = 0;
  }

  return `${roundedBMI}.${diff}`;
}

const CommonInfo = ({ patient }) => {
  const history = useHistory();
  const modalRemoveConnectionConfirm = React.useRef(null);

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: null, message: '', error: '', isLoading: false, isError: false,
    },
  );

  React.useEffect(() => {
    if (modalRemoveConnectionConfirm.current) {
      M.Modal.init(modalRemoveConnectionConfirm.current, {});
    }
  });

  const handleHighRiskChange = (e) => {
    dispatch({ type: 'FETCH_INIT' });

    const value = e.target.checked;

    const opts = {
      id: patient.id,
      high_risk: value,
    };

    sendRequest('update_user_by_id', 'PUT', opts)
      .then((response) => {
        if (response.status === 'Failure') {
          dispatch({ type: 'FETCH_FAILURE', error: response.errors });
        } else {
          dispatch({ type: 'FETCH_SUCCESS', message: response.message });
        }
      })
      .catch(() => {
        dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' });
      });
  };

  const handleRemoveConnection = () => {
    dispatch({ type: 'FETCH_INIT' });

    const opts = {
      patient_id: patient.id,
    };

    sendRequest('delete_connected_patient', 'DELETE', opts)
      .then((response) => {
        if (response.status === 'Failure') {
          dispatch({ type: 'FETCH_FAILURE', error: response.errors });
        } else {
          history.replace('/');
          dispatch({ type: 'FETCH_SUCCESS', message: response.message });
        }
      })
      .catch(() => {
        dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' });
      });
  };

  return (
    <div>
      <h5 className="header-with-button mt4 mb4">
        Common Info
        <a href={`/patients/${patient.id}/edit`} className="btn btn-small white black-text right hide-on-print">Edit</a>
        <button type="button" data-target="remove-modal" className="btn btn-small white red-text right hide-on-print mr2 modal-trigger">
          Remove
        </button>
      </h5>

      <div className={`form-messages ${state.message ? '' : 'hide'}`}>{state.message}</div>
      <div className={`form-errors ${state.error ? '' : 'hide'}`}>{state.error}</div>

      <table>
        <tbody>
          <tr>
            <td><b>First Name</b></td>
            <td>{patient.first_name}</td>
          </tr>
          <tr>
            <td><b>Last Name</b></td>
            <td>{patient.last_name}</td>
          </tr>
          <tr>
            <td><b>Phone Number</b></td>
            <td>{patient.phone_number}</td>
          </tr>
          <tr>
            <td><b>Email</b></td>
            <td>{patient.email}</td>
          </tr>
          <tr>
            <td><b>Gender</b></td>
            <td>{patient.gender}</td>
          </tr>
          <tr>
            <td><b>Date of Birth</b></td>
            <td>{patient.dob_date}</td>
          </tr>

          <tr>
            <td><b>Height</b></td>
            <td>
              {
                patient.height_type === 'feet'
                  ? `${patient.height.split('.')[0]} feet ${patient.height.split('.')[1]} inches`
                  : `${patient.height} ${patient.height_type}`
              }
            </td>
          </tr>
          <tr>
            <td><b>Weight</b></td>
            <td>
              {`${patient.weight} ${patient.weight_type}`}
            </td>
          </tr>
          <tr>
            <td><b>BMI</b></td>
            <td>
              {
              patient.height_type === 'feet'
                ? calculateImperialBMI(patient)
                : calculateMetricBMI(patient)
              }
            </td>
          </tr>
          <tr>
            <td><b>Needs Attention</b></td>
            <td>
              <div className="switch high-risk-switch">
                <label>
                  No
                  <input
                    name="high_risk"
                    type="checkbox"
                    defaultChecked={patient.high_risk}
                    onChange={handleHighRiskChange}
                  />
                  <span className="lever" />
                  Yes
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td><b>Chronic Conditions</b></td>
            <td>
              {
                patient.chronic_conditions
                  ? patient.chronic_conditions.map((condition) => (
                    <span key={condition} className="chip">
                      {condition}
                    </span>
                  ))
                  : null
              }
            </td>
          </tr>
          <tr>
            <td><b>Medication</b></td>
            <td className="wrap-newlines">{patient.medications}</td>
          </tr>
          <tr>
            <td><b>Conditions</b></td>
            <td className="wrap-newlines">{patient.conditions}</td>
          </tr>
          <tr>
            <td><b>Surgeries / Treatments</b></td>
            <td className="wrap-newlines">{patient.treatments}</td>
          </tr>

          <tr>
            <td><b>Mio BPM Device ID</b></td>
            <td>{patient.mio_connect_bpm_id}</td>
          </tr>

          <tr>
            <td><b>Mio Scale Device ID</b></td>
            <td>{patient.mio_connect_scale_id}</td>
          </tr>

          <tr>
            <td><b>Emergency Contact</b></td>
            <td className="wrap-newlines">
              <dl>
                <strong>Name: </strong>
                {patient.contact_name}
                <br />

                <strong>Phone: </strong>
                {patient.contact_phone_number}
                <br />

                <strong>Email: </strong>
                {patient.contact_email}
                <br />

                <strong>Relationship: </strong>
                {patient.contact_relation}
              </dl>
            </td>
          </tr>
        </tbody>
      </table>
      <ConnectedDevices patientId={patient.id} />

      {/* Modals */}
      <div ref={modalRemoveConnectionConfirm} id="remove-modal" className="modal">
        <div className="modal-content">
          <h5>Remove Connection</h5>
          <p>Are you sure you want to remove this patient from the patient dashboard?</p>
        </div>
        <div className="modal-footer">
          <a href="#!" className="modal-close waves-effect btn-flat" onClick={handleRemoveConnection}>Yes</a>
          <a href="#!" className="modal-close waves-effect btn-flat">No</a>
        </div>
      </div>

    </div>
  );
};

CommonInfo.propTypes = {
  patient: PropTypes.shape({
    id: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    weight: PropTypes.string.isRequired,
    weight_type: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    height_type: PropTypes.string.isRequired,
    high_risk: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    dob_date: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
    medications: PropTypes.string,
    conditions: PropTypes.string,
    treatments: PropTypes.string,
    mio_connect_bpm_id: PropTypes.string,
    mio_connect_scale_id: PropTypes.string,
    rpm_device_bpm_id: PropTypes.string,
    phone_number: PropTypes.string,
    contact_name: PropTypes.string,
    contact_phone_number: PropTypes.string,
    contact_email: PropTypes.string,
    contact_relation: PropTypes.string,
    chronic_conditions: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default CommonInfo;
