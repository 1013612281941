import React from 'react';

import M from '@materializecss/materialize';
import { useHistory, useParams } from 'react-router-dom';
import { reducer, sendRequest } from '../../../utils';
import Loader from '../../../Components/Loader';
import Select from '../../../Components/Select';

const TYPES = {
  BPM: 'Blood Pressure',
  Pulse: 'Pulse',
  Weight: 'Weight',
  PulseOximeter: 'Blood Oxygen Saturation',
  Thermometer: 'Temperature',
  BGM: 'Blood Glucose',
};

const TYPES2UNITS = {
  BPM: {
    mmHg: 'mmHg',
    kPa: 'kPa',
  },
  Pulse: {
    bpm: 'bpm',
  },
  Weight: {
    lbs: 'lbs',
    kg: 'kg',
  },
  PulseOximeter: {
    '%SpO2': '%SpO2',
  },
  Thermometer: {
    '°C': '°C',
    '°F': '°F',
  },
  BGM: {
    'mg/dL': 'mg/dL',
  },
};

const TYPES2FIELDS = {
  Weight: 'Weight',
  PulseOximeter: 'BloodOxygenSaturation',
  PulseRate: 'PulseRate',
  Pulse: 'Pulse',
  Systolic: 'Systolic',
  Diastolic: 'Diastolic',
  Thermometer: 'Temperature',
  BGM: 'BloodGlucose',
};

const INTEGER_TYPES = ['BPM', 'Pulse', 'PulseOximeter', 'BGM'];

const AddAlertZone = () => {
  const { patientId } = useParams();
  const history = useHistory();

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isError: false, isLoading: false,
    },
  );

  const [type, setType] = React.useState('BPM');
  const [field, setField] = React.useState(TYPES2FIELDS[type]);
  const [units, setUnits] = React.useState(TYPES2UNITS[type]);
  const [existingTypes, setExistingTypes] = React.useState([]);

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('get_alert_zones', 'GET', { patient_id: patientId })
      .then((response) => {
        if (response.status === 'Success') {
          // Save existing alert zones types to disable them in the select
          setExistingTypes(response.alertzones.map((az) => az.field_type));

          dispatch({ type: 'FETCH_SUCCESS' });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.errors });
        }
      })
      .catch(() => {
        dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' });
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch({ type: 'FETCH_INIT' });

    const opts = Object.fromEntries(new FormData(e.target));

    opts.patient_id = patientId;

    // Field Group needed to join alert zones
    opts.field_group = (new Date()).getTime();

    if (opts.field_type === 'BPM') {
      // Send data in two requests: systolic and diastolic
      const systolicOpts = {
        patient_id: opts.patient_id,
        field_group: opts.field_group,
        field_name: 'Systolic',
        field_type: opts.field_type,
        high_value: opts['high_value-0'],
        low_value: opts['low_value-0'],
        value_unit: opts.value_unit,
      };

      const diastolicOpts = {
        patient_id: opts.patient_id,
        field_group: opts.field_group,
        field_name: 'Diastolic',
        field_type: opts.field_type,
        high_value: opts['high_value-1'],
        low_value: opts['low_value-1'],
        value_unit: opts.value_unit,
      };

      Promise.all([
        sendRequest('create_alert_zone', 'POST', systolicOpts),
        sendRequest('create_alert_zone', 'POST', diastolicOpts),
      ])
        .then(([r1, r2]) => {
          if (r1.status === 'Success' && r2.status === 'Success') {
            if (e.nativeEvent.submitter.value === 'submit-and-go-back') {
              history.goBack();
            }
            dispatch({ type: 'FETCH_SUCCESS', message: r1.message });

            // Do not allow to add another alert zone with the same type
            setExistingTypes([...existingTypes, opts.field_type]);
          } else {
            dispatch({ type: 'FETCH_FAILURE', error: r1.errors || r2.errors });
          }
        })
        .catch(() => dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' }));
    } else {
      sendRequest('create_alert_zone', 'POST', opts)
        .then((response) => {
          if (response.status === 'Success') {
            if (e.nativeEvent.submitter.value === 'submit-and-go-back') {
              history.goBack();
            }
            dispatch({ type: 'FETCH_SUCCESS', message: response.message });

            // Do not allow to add another alert zone with the same type
            setExistingTypes([...existingTypes, opts.field_type]);
          } else {
            dispatch({ type: 'FETCH_FAILURE', error: response.errors });
          }
        })
        .catch(() => dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' }));
    }
  };

  React.useEffect(() => {
    M.AutoInit();
  });

  React.useEffect(() => {
    setUnits(TYPES2UNITS[type]);
    setField(TYPES2FIELDS[type]);

    // Hack to update units element
    setTimeout(M.AutoInit, 100);
  }, [type]);

  // The two function below needed to disable decimals for specific types of alert zones
  // For now, it will remove decimals for BPM and Pulse
  const onKeyDown = (event) => {
    if (INTEGER_TYPES.includes(type) && ['.', ','].includes(event.key)) {
      event.preventDefault();
    }
  };

  const onInput = (event) => {
    if (INTEGER_TYPES.includes(type)) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = event.target.value.replace(/[^0-9]*/g, '');
    }
  };

  // We do not want to allow to create alert zones for the same type twice
  const getAllowedTypes = () => Object.keys(TYPES)
    .filter((key) => !existingTypes.includes(key))
    .reduce((obj, key) => {
      obj[key] = TYPES[key];
      return obj;
    }, {});

  const allowedTypes = getAllowedTypes();

  if (!Object.keys(allowedTypes).length) {
    return (
      <>
        <h5 className="header mt4">
          New Alert zone
        </h5>

        <h6 className="">
          You have already created all the alert zones for this patient.
        </h6>

        <button
          type="button"
          value="close"
          className="btn white black-text mt2"
          onClick={() => history.goBack()}
        >
          Close
        </button>
      </>
    );
  }

  return (
    <>
      <h5 className="header mt4">
        New Alert zone
      </h5>

      {
          state.isLoading
            ? <Loader />
            : (
              <div className="row">
                <form className="col s12" onSubmit={handleSubmit}>
                  <div className={`form-messages ${state.message ? '' : 'hide'}`}>{state.message}</div>
                  <div className={`form-errors ${state.isError ? '' : 'hide'}`}>{state.error}</div>

                  <div className="row">
                    <div className="input-field col s12 m6">
                      <Select
                        name="field_type"
                        label="Type"
                        values={allowedTypes}
                        defaultValue={type}
                        onChange={(e) => setType(e.target.value)}
                      />
                      <small>Only one alert zone for each vital can be created. Please, go back to alert zones list to see other vitals.</small>
                    </div>
                    <div className="input-field col s12 m6">
                      <Select
                        name="value_unit"
                        label="Units"
                        values={units}
                        defaultValue=""
                      />
                    </div>

                    <input type="hidden" name="field_name" defaultValue={field} />
                  </div>

                  {
                    type === 'BPM'
                      ? (
                        <>
                          <h6>Systolic</h6>
                          <div className="row">
                            <div className="input-field col s12 m6">
                              <label htmlFor="high_value-0">Max. value</label>
                              <input
                                id="high_value-0"
                                name="high_value-0"
                                type="number"
                                className="validate"
                                step={INTEGER_TYPES.includes(type) ? 1 : 0.1}
                                onKeyDown={onKeyDown}
                                onInput={onInput}
                                required
                              />
                            </div>

                            <div className="input-field col s12 m6">
                              <label htmlFor="low_value-0">Min. value</label>
                              <input
                                id="low_value-0"
                                name="low_value-0"
                                type="number"
                                className="validate"
                                step={INTEGER_TYPES.includes(type) ? 1 : 0.1}
                                onKeyDown={onKeyDown}
                                onInput={onInput}
                                required
                              />
                            </div>
                          </div>

                          <h6>Diastolic</h6>
                          <div className="row">
                            <div className="input-field col s12 m6">
                              <label htmlFor="high_value-1">Max. value</label>
                              <input
                                id="high_value-1"
                                name="high_value-1"
                                type="number"
                                className="validate"
                                step={INTEGER_TYPES.includes(type) ? 1 : 0.1}
                                onKeyDown={onKeyDown}
                                onInput={onInput}
                                required
                              />
                            </div>

                            <div className="input-field col s12 m6">
                              <label htmlFor="low_value-1">Min. value</label>
                              <input
                                id="low_value-1"
                                name="low_value-1"
                                type="number"
                                className="validate"
                                step={INTEGER_TYPES.includes(type) ? 1 : 0.1}
                                onKeyDown={onKeyDown}
                                onInput={onInput}
                                required
                              />
                            </div>
                          </div>
                        </>
                      )
                      : (
                        <>
                          <div className="row">
                            <div className="input-field col s12 m6">
                              <label htmlFor="high_value">Max. value</label>
                              <input
                                id="high_value"
                                name="high_value"
                                type="number"
                                className="validate"
                                step={INTEGER_TYPES.includes(type) ? 1 : 0.1}
                                onKeyDown={onKeyDown}
                                onInput={onInput}
                                required
                              />
                            </div>

                            <div className="input-field col s12 m6">
                              <label htmlFor="low_value">Min. value</label>
                              <input
                                id="low_value"
                                name="low_value"
                                type="number"
                                className="validate"
                                step={INTEGER_TYPES.includes(type) ? 1 : 0.1}
                                onKeyDown={onKeyDown}
                                onInput={onInput}
                                required
                              />
                            </div>
                          </div>
                        </>
                      )
                  }

                  <button type="submit" value="submit" className="btn black mt2">Save</button>
                  <button type="submit" value="submit-and-go-back" className="btn black mt2 ml2">Save And Close</button>
                  <button
                    type="button"
                    value="close"
                    className="btn white black-text mt2 ml2"
                    onClick={() => history.goBack()}
                  >
                    Close
                  </button>
                </form>
              </div>
            )
        }
    </>
  );
};

AddAlertZone.propTypes = {};

export default AddAlertZone;
