import React from 'react';
import M from '@materializecss/materialize';
import PropTypes from 'prop-types';
import moment from 'moment';
import Select from '../Components/Select';

const RANGE_BUTTONS = [
  { id: 'daily', text: 'Day', delta: 'day' },
  { id: 'weekly', text: 'Week', delta: 'isoWeek' },
  { id: 'monthly', text: 'Month', delta: 'month' },
  { id: 'yearly', text: 'Year', delta: 'year' },
  { id: 'total', text: 'Total', delta: null },
  { id: 'custom', text: 'Custom', delta: null, classes: 'hide' },
];

const PERIODS = {
  0: 'Select period',
  7: '7 days',
  14: '14 days',
  21: '21 days',
  28: '28 days',
  30: '30 days',
  60: '60 days',
  90: '90 days',
};

const RangeButtonsGroup = ({
  defaultInterval, initialStartDate = null, initialEndDate = null, callback,
}) => {
  const customButton = RANGE_BUTTONS[RANGE_BUTTONS.length - 1];
  const activeButton = RANGE_BUTTONS.filter((b) => b.id === defaultInterval)[0] || customButton;
  const idPostfix = (new Date()).getMilliseconds();
  const startDateButtonId = `date_start-${idPostfix}`;
  const endDateButtonId = `date_end-${idPostfix}`;

  const [state, setState] = React.useState(
    {
      activeButton,
      activePeriod: 0,
      startDate: initialStartDate || moment().subtract(1, activeButton.delta).toDate(),
      endDate: initialEndDate || moment().toDate(),
    },
  );

  const handleButtonClick = (button) => {
    const newDate = moment();
    let newStartDate = null;
    let newEndDate = null;

    if (button.delta) {
      // pre-defined intervals
      newEndDate = newDate.toDate();
      newStartDate = newDate.startOf(button.delta).toDate();
    } else if (button.id === 'total') {
      newEndDate = newDate.toDate();
      // assume that records for the two years is enough
      newDate.subtract(2, 'years');
      newStartDate = newDate.toDate();
    } else {
      // custom interval
      // do nothing?
    }

    setState({
      activeButton: button,
      activePeriod: 0,
      startDate: newStartDate,
      endDate: newEndDate,
    });
  };

  const handlePeriodChange = (e) => {
    const period = Number(e.target.value);

    if (period) {
      const newDate = moment();
      let newStartDate = null;
      const newEndDate = newDate.toDate();

      newDate.subtract(period, 'days');
      newStartDate = newDate.toDate();

      setState({
        activeButton: customButton,
        activePeriod: period,
        startDate: newStartDate,
        endDate: newEndDate,
      });
    }
  };

  React.useEffect(() => {
    M.Datepicker.init(
      document.querySelector(`#${startDateButtonId}`),
      {
        defaultDate: state.startDate,
        setDefaultDate: true,
        autoClose: true,
        onSelect: (newDate) => setState({
          ...state,
          ...{ startDate: newDate, activeButton: customButton, activePeriod: 0 },
        }),
      },
    );
    M.Datepicker.init(
      document.querySelector(`#${endDateButtonId}`),
      {
        defaultDate: state.endDate,
        setDefaultDate: true,
        autoClose: true,
        onSelect: (newDate) => setState({
          ...state,
          ...{ endDate: newDate, activeButton: customButton, activePeriod: 0 },
        }),
      },
    );

    // M.updateTextFields();
  }, [state.startDate, state.endDate]);

  React.useEffect(() => {
    callback({ startDate: state.startDate });
  }, [state.startDate]);

  React.useEffect(() => {
    callback({ endDate: state.endDate });
  }, [state.endDate]);

  return (
    <div className="mt4">
      <div>
        {RANGE_BUTTONS.map(
          (b) => (
            <RangeButton
              key={b.id}
              button={b}
              isActive={state.activeButton.id === b.id}
              handleButtonClick={handleButtonClick}
            />
          ),
        )}
      </div>
      <div className="row">
        <form className="col s12">
          <div className="row">
            <div className="input-field col s4">
              <label htmlFor={startDateButtonId}>Start Date</label>
              <input
                id={startDateButtonId}
                type="text"
                className="datepicker"
              />
            </div>
            <div className="input-field col s4">
              <label htmlFor={endDateButtonId}>End Date</label>
              <input
                id={endDateButtonId}
                type="text"
                className="datepicker"
              />
            </div>
            <div className="input-field col s4">
              <Select
                label="Period"
                name="period"
                values={PERIODS}
                defaultValue={String(state.activePeriod)}
                onChange={handlePeriodChange}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

RangeButtonsGroup.propTypes = {
  defaultInterval: PropTypes.string.isRequired,
  initialStartDate: PropTypes.instanceOf(Date),
  initialEndDate: PropTypes.instanceOf(Date),
  callback: PropTypes.func.isRequired,
};

RangeButtonsGroup.defaultProps = {
  initialStartDate: null,
  initialEndDate: null,
};

const RangeButton = ({ button, isActive, handleButtonClick }) => {
  const onTypeButtonClick = (e) => {
    e.preventDefault();
    handleButtonClick(button);
  };

  return (
    <a
      key={button.id}
      href="#/"
      className={`range-button btn black mr1 mb2 ${isActive ? 'disabled' : ''} ${button.classes || ''}`}
      onClick={onTypeButtonClick}
    >
      {button.text}
    </a>
  );
};

RangeButton.propTypes = {
  button: PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    delta: PropTypes.string,
    classes: PropTypes.string,
  }).isRequired,
  isActive: PropTypes.bool.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
};

export default RangeButtonsGroup;
