import M from '@materializecss/materialize';
import React from 'react';
import PropTypes from 'prop-types';

import './Editable.css';

const Editable = ({ label, defaultValue, placeholder, onChange, ...props }) => {
  const [isEditing, setEditing] = React.useState(false);
  const [content, setContent] = React.useState(defaultValue);
  const textareaRef = React.useRef(null);

  React.useEffect(() => {
    // Resize <textarea> elements
    setTimeout(
      () => {
        document.querySelectorAll('textarea').forEach(
          (textarea) => M.textareaAutoResize(textarea),
        );
      }, 250,
    );
  });

  React.useEffect(() => {
    if (textareaRef && textareaRef.current && isEditing === true) {
      textareaRef.current.focus();
    }
  }, [isEditing, textareaRef]);

  const handleKeyDown = (event) => {
    const { key } = event;

    if (['Escape', 'Tab'].indexOf(key) > -1) {
      setEditing(false);

      // Notify about changes
      onChange(content);
    }
  };

  const handleChange = (event) => {
    setContent(event.target.value);
  };

  const handleBlue = () => {
    setEditing(false);

    // Notify about changes
    onChange(content);
  };

  return (
    <div {...props}>
      {
        isEditing
          ? (
            <>
              {label && <label htmlFor="abc">{label}</label>}
              <textarea
                id="abc"
                name="abc"
                ref={textareaRef}
                className="materialize-textarea"
                defaultValue={content}
                onChange={(e) => handleChange(e)}
                onBlur={(e) => handleBlue(e)}
                onKeyDown={(e) => handleKeyDown(e)}
              />
            </>

          )
          : (
            <div
              className="editable text-pre-wrap"
              onClick={() => setEditing(true)}
              role="presentation"
            >
              {content || placeholder || 'Click to edit'}
            </div>
          )
      }
    </div>
  );
};

Editable.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

Editable.defaultProps = {
  label: '',
  placeholder: 'Click to edit',
};

export default Editable;
