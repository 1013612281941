import moment from 'moment';
import M from '@materializecss/materialize';
import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import Loader from '../Components/Loader';
import { reducer, sendRequest } from '../utils';
import Table from '../Components/Table';

const Requests = () => {
  React.useEffect(() => {
    M.Tabs.init(document.querySelector('.tabs'), {});
  });
  return (
    <>
      <h4>Requests</h4>

      <div className="row">
        <div className="col s12">
          <ul className="tabs">
            <li className="tab col s3"><a className="active" href="#bpm">Pending Requests</a></li>
            <li className="tab col s3"><a href="#entries">Sent Requests</a></li>
          </ul>
        </div>
        <div id="bpm" className="col s12 pt4">
          <PendingRequests />
        </div>
        <div id="entries" className="col s12 pt4">
          <MyRequests />
        </div>
      </div>
    </>
  );
};

const PendingRequests = () => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isLoading: true, isError: false,
    },
  );

  const handleFetchRequests = React.useCallback(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('getrequests', 'GET')
      .then((response) => {
        if (response.status === 'FAILURE') {
          dispatch({ type: 'FETCH_FAILURE', error: response.errors });
          return;
        }

        // Preprocess requests
        const requests = response.requests.map(
          (r) => ({
            id: r.id,
            created_at: moment(new Date(r.created_at)).format('MMMM DD, YYYY h:mm:ss'),
            name: `${r.user_first_name} ${r.user_last_name}`,
          }),
        );

        dispatch({
          type: 'FETCH_SUCCESS',
          payload: requests,
        });
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
      });
  }, []);

  React.useEffect(() => {
    handleFetchRequests();
  }, [handleFetchRequests]);

  const handleApprove = (requestId) => {
    sendRequest('updaterequest', 'PUT', { id: requestId, status: 1 })
      .then((response) => {
        if (response.status === 'Success') {
          handleFetchRequests();
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.errors });
        }
      });
  };

  const handleDecline = (requestId) => {
    sendRequest('updaterequest', 'PUT', { id: requestId, status: 2 })
      .then((response) => {
        if (response.status === 'Success') {
          handleFetchRequests();
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.errors });
        }
      });
  };

  return (
    <>
      {/* <div className="mb2">
        Requests received from patients
      </div> */}

      {state.isError && <p>{state.error}</p>}

      {state.isLoading ? (
        <Loader />
      ) : (
        <>
          {state.data.length
            ? (
              <RequestsTable
                requests={state.data}
                actions={[
                  {
                    label: 'Approve',
                    callback: handleApprove,
                  },
                  {
                    label: 'Decline',
                    callback: handleDecline,
                    color: 'red',
                  },
                ]}
              />
            )
            : <p>You have no pending requests</p>}

        </>
      )}
    </>
  );
};

const MyRequests = () => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isLoading: true, isError: false,
    },
  );

  const handleFetchRequests = React.useCallback(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('get_pending_requests', 'GET')
      .then((response) => {
        if (response.status === 'FAILURE') {
          dispatch({ type: 'FETCH_FAILURE', error: response.errors });
          return;
        }
        // Preprocess requests
        const requests = response.requests.map(
          (r) => ({
            id: r.id,
            created_at: moment(new Date(r.created_at)).format('MMMM DD, YYYY h:mm:ss'),
            name: `${r.receiver_first_name} ${r.receiver_last_name}`,
          }),
        );

        dispatch({
          type: 'FETCH_SUCCESS',
          payload: requests,
        });
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
      });
  }, []);

  const handleRemind = (requestId) => {
    sendRequest('remind_pending_request', 'POST', { id: requestId })
      .then((response) => {
        if (response.status === 'Success') {
          dispatch({ type: 'FETCH_SUCCESS', message: response.message });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.errors });
        }
      });
  };

  const handleCancel = (requestId) => {
    sendRequest('deleterequest', 'DELETE', { id: requestId })
      .then((response) => {
        if (response.status === 'Success') {
          handleFetchRequests();
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.errors });
        }
      });
  };

  React.useEffect(() => {
    handleFetchRequests();
  }, [handleFetchRequests]);

  if (state.isLoading) {
    return <Loader />;
  }

  return (
    <>
      {/* <div className="mb2">
        Requests sent to patients
      </div> */}

      <Link to="/requests/add" className="btn black mb2">Send a New Request</Link>

      {state.isError && <p className="form-errors">{state.error}</p>}
      {state.message && <p className="form-messages">{state.message}</p>}

      {state.data.length
        ? (
          <RequestsTable
            requests={state.data}
            actions={[
              {
                label: 'Remind',
                callback: handleRemind,
              },
              {
                label: 'Cancel',
                callback: handleCancel,
              },
            ]}
          />
        )
        : <p>You have no sent unconfirmed requests</p>}
    </>
  );
};

const RequestsTable = ({ requests, actions }) => {
  const RequestActions = ({ row: { original: cell } }) => (
    <div>
      {actions.map((action) => (
        <a
          key={action.label}
          className={`btn mr2 mb2 ${action.color || 'black'}`}
          href="#/"
          onClick={() => action.callback(cell.id)}
        >
          {action.label}
        </a>
      ))}
    </div>
  );

  RequestActions.propTypes = {
    row: PropTypes.shape({
      original: PropTypes.shape({
        id: PropTypes.string,
      }),
    }).isRequired,
  };

  const columns = [
    {
      Header: 'Created At',
      accessor: 'created_at',
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Actions',
      Cell: RequestActions,
    },
  ];

  return (
    <Table columns={columns} data={requests} centered />
  );
};

RequestsTable.propTypes = {
  requests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      callback: PropTypes.func.isRequired,
      color: PropTypes.string,
    }),
  ).isRequired,
};

export default Requests;
