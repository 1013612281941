import React from 'react';

import M from '@materializecss/materialize';
import { useHistory, useParams } from 'react-router-dom';
import { reducer, round, sendRequest } from '../../../utils';
import Loader from '../../../Components/Loader';
import Select from '../../../Components/Select';

const TYPES = {
  BPM: 'Blood Pressure',
  Pulse: 'Pulse',
  Weight: 'Weight',
  PulseOximeter: 'Blood Oxygen Saturation',
  Thermometer: 'Temperature',
  BGM: 'Blood Glucose',
};

const TYPE2FIELD = {
  PulseOximeter: 'BloodOxygenSaturation',
  Weight: 'Weight',
  Pulse: 'Pulse',
  Thermometer: 'Temperature',
  BGM: 'BloodGlucose',
};

const INTEGER_TYPES = ['BPM', 'Pulse', 'PulseOximeter', 'BGM'];

const TYPE2UNITS = {
  BPM: {
    mmHg: 'mmHg',
    kPa: 'kPa',
  },
  Pulse: {
    bpm: 'bpm',
  },
  Weight: {
    lbs: 'lbs',
    kg: 'kg',
  },
  PulseOximeter: {
    '%SpO2': '%SpO2',
  },
  Thermometer: {
    '°C': '°C',
    '°F': '°F',
  },
  BGM: {
    'mg/dL': 'mg/dL',
  },
};

const EditAlertZone = () => {
  const { patientId, groupId } = useParams();
  const history = useHistory();

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isError: false, isLoading: false,
    },
  );

  const [type, setType] = React.useState('BPM');
  const [units, setUnits] = React.useState(TYPE2UNITS[type]);

  const fetchAlertZones = async () => {
    await sendRequest('get_alert_zones', 'GET', { patient_id: patientId })
      .then((response) => {
        if (response.status === 'Success') {
          // Filter by alert zone group
          const alertZones = response.alertzones.filter((az) => az.field_group === groupId);

          setType(alertZones[0].field_type);
          setUnits(TYPE2UNITS[alertZones[0].field_type]);

          dispatch({ type: 'FETCH_SUCCESS', payload: alertZones });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.errors });
        }
      })
      .catch(() => {
        dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' });
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch({ type: 'FETCH_INIT' });

    const opts = Object.fromEntries(new FormData(e.target));

    if (type === 'BPM') {
      // Send data in two requests: systolic and diastolic
      const systolicOpts = {
        id: opts['id-0'],
        field_name: 'Systolic',
        high_value: opts['high_value-0'],
        low_value: opts['low_value-0'],
        value_unit: opts.value_unit,
      };

      const diastolicOpts = {
        id: opts['id-1'],
        field_name: 'Diastolic',
        high_value: opts['high_value-1'],
        low_value: opts['low_value-1'],
        value_unit: opts.value_unit,
      };

      Promise.all([
        sendRequest('update_alert_zone', 'PUT', systolicOpts),
        sendRequest('update_alert_zone', 'PUT', diastolicOpts),
      ])
        .then(async ([r1, r2]) => {
          if (r1.status === 'Success' && r2.status === 'Success') {
            if (e.nativeEvent.submitter.value === 'submit-and-go-back') {
              history.goBack();
            }
            await fetchAlertZones();
            dispatch({ type: 'FETCH_SUCCESS', message: r1.message });
          } else {
            dispatch({ type: 'FETCH_FAILURE', error: r1.errors || r2.errors });
          }
        })
        .catch(() => dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' }));
    } else {
      sendRequest('update_alert_zone', 'PUT', opts)
        .then(async (response) => {
          if (response.status === 'Success') {
            if (e.nativeEvent.submitter.value === 'submit-and-go-back') {
              history.goBack();
            }
            await fetchAlertZones();
            dispatch({ type: 'FETCH_SUCCESS', message: response.message });
          } else {
            dispatch({ type: 'FETCH_FAILURE', error: response.errors });
          }
        })
        .catch(() => dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' }));
    }
  };

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    fetchAlertZones();
  }, []);

  React.useEffect(() => {
    M.AutoInit();
    M.updateTextFields();
  });

  // The two function below needed to disable decimals for specific types of alert zones
  // For now, it will remove decimals for BPM and Pulse
  const onKeyDown = (event) => {
    if (INTEGER_TYPES.includes(type) && ['.', ','].includes(event.key)) {
      event.preventDefault();
    }
  };

  const onInput = (event) => {
    if (INTEGER_TYPES.includes(type)) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = event.target.value.replace(/[^0-9]*/g, '');
    }
  };

  const getAlertZoneValue = (name, value) => {
    for (let i = 0; i < state.data.length; i += 1) {
      if (state.data[i].field_name === name) {
        return state.data[i][value];
      }
    }
    return null;
  };

  const getDefaultValue = (name, value) => {
    if (INTEGER_TYPES.includes(type)) {
      return round(getAlertZoneValue(name, value));
    }
    return getAlertZoneValue(name, value);
  };

  return (
    <>
      <h5 className="header mt4">
        Edit Alert Zone
      </h5>

      {
        state.isLoading
          ? <Loader />
          : (
            <div className="row">
              <form className="col s12" onSubmit={handleSubmit}>
                <div className={`form-messages ${state.message ? '' : 'hide'}`}>{state.message}</div>
                <div className={`form-errors ${state.isError ? '' : 'hide'}`}>{state.error}</div>

                <div className="row">
                  <div className="input-field col s12 m6">
                    <label htmlFor="field_type">Type</label>
                    <input
                      id="field_type"
                      name="field_type"
                      type="text"
                      readOnly
                      defaultValue={TYPES[type]}
                    />
                  </div>

                  <div className="input-field col s12 m6">
                    <Select
                      name="value_unit"
                      label="Units"
                      values={units}
                      defaultValue={state.data[0]?.value_unit}
                    />
                  </div>
                </div>

                {
                  type === 'BPM'
                    ? (
                      <>
                        <h6>Systolic</h6>
                        <div className="row">
                          <div className="input-field col s12 m6">
                            <label htmlFor="high_value-0">Max. value</label>
                            <input
                              id="high_value-0"
                              name="high_value-0"
                              type="number"
                              className="validate"
                              step={INTEGER_TYPES.includes(type) ? 1 : 0.1}
                              defaultValue={getDefaultValue('Systolic', 'high_value')}
                              onKeyDown={onKeyDown}
                              onInput={onInput}
                              required
                            />
                          </div>

                          <div className="input-field col s12 m6">
                            <label htmlFor="low_value-0">Min. value</label>
                            <input
                              id="low_value-0"
                              name="low_value-0"
                              type="number"
                              className="validate"
                              step={INTEGER_TYPES.includes(type) ? 1 : 0.1}
                              defaultValue={getDefaultValue('Systolic', 'low_value')}
                              onKeyDown={onKeyDown}
                              onInput={onInput}
                              required
                            />
                          </div>
                        </div>

                        <h6>Diastolic</h6>
                        <div className="row">
                          <div className="input-field col s12 m6">
                            <label htmlFor="high_value-1">Max. value</label>
                            <input
                              id="high_value-1"
                              name="high_value-1"
                              type="number"
                              className="validate"
                              step={INTEGER_TYPES.includes(type) ? 1 : 0.1}
                              defaultValue={getDefaultValue('Diastolic', 'high_value')}
                              onKeyDown={onKeyDown}
                              onInput={onInput}
                              required
                            />
                          </div>

                          <div className="input-field col s12 m6">
                            <label htmlFor="low_value-1">Min. value</label>
                            <input
                              id="low_value-1"
                              name="low_value-1"
                              type="number"
                              className="validate"
                              step={INTEGER_TYPES.includes(type) ? 1 : 0.1}
                              defaultValue={getDefaultValue('Diastolic', 'low_value')}
                              onKeyDown={onKeyDown}
                              onInput={onInput}
                              required
                            />
                          </div>
                        </div>

                        <input type="hidden" name="id-0" defaultValue={getAlertZoneValue('Systolic', 'id')} />
                        <input type="hidden" name="id-1" defaultValue={getAlertZoneValue('Diastolic', 'id')} />
                      </>
                    )
                    : (
                      <>
                        <div className="row">
                          <div className="input-field col s12 m6">
                            <label htmlFor="high_value">Max. value</label>
                            <input
                              id="high_value"
                              name="high_value"
                              type="number"
                              className="validate"
                              step={INTEGER_TYPES.includes(type) ? 1 : 0.1}
                              defaultValue={getDefaultValue(TYPE2FIELD[type], 'high_value')}
                              onKeyDown={onKeyDown}
                              onInput={onInput}
                              required
                            />
                          </div>

                          <div className="input-field col s12 m6">
                            <label htmlFor="low_value">Min. value</label>
                            <input
                              id="low_value"
                              name="low_value"
                              type="number"
                              className="validate"
                              step={INTEGER_TYPES.includes(type) ? 1 : 0.1}
                              defaultValue={getDefaultValue(TYPE2FIELD[type], 'low_value')}
                              onKeyDown={onKeyDown}
                              onInput={onInput}
                              required
                            />
                          </div>
                        </div>
                        <input type="hidden" name="id" defaultValue={state.data[0].id} />
                      </>
                    )
                }

                <button type="submit" value="submit" className="btn black mt2">Save</button>
                <button type="submit" value="submit-and-go-back" className="btn black mt2 ml2">Save And Close</button>
                <button
                  type="button"
                  value="close"
                  className="btn white black-text mt2 ml2"
                  onClick={() => history.goBack()}
                >
                  Close
                </button>
              </form>
            </div>
          )
      }
    </>
  );
};

EditAlertZone.propTypes = {};

export default EditAlertZone;
