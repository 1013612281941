import M from '@materializecss/materialize';
import React from 'react';

import Loader from './Components/Loader';
import { reducer, sendRequest } from './utils';

const Feedback = () => {
  const [feedback, setFeedback] = React.useState('');
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isLoading: false, isError: false,
    },
  );

  const onSubmitClick = async (e) => {
    e.preventDefault();
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('feedback', 'POST', { feedback })
      .then((response) => {
        if (response.status === 'Success') {
          dispatch({ type: 'FETCH_SUCCESS', message: response.message });
          // TODO: Use toats instead of alerts?
          // M.toast({ html: 'Feedback sent successfully' });
          setFeedback('');
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.errors });
          // M.toast({ html: 'Feedback failed to send' });
        }
      });
  };

  React.useEffect(() => {
    M.updateTextFields();
  });
  console.log(state);

  return (
    <>
      <h4 className="mt4 mb4">Submit Feedback</h4>
      <p>
        For any questions, comments, or suggestions, please submit below. A member of our team will respond as soon as possible.
      </p>
      { state.isLoading
        ? <Loader />
        : (
          <div className="row">
            <form className="col s12">
              <div className={`form-messages ${state.message ? '' : 'hide'}`}>{state.message}</div>
              <div className={`form-errors ${state.error ? '' : 'hide'}`}>{state.error}</div>
              <div className="row">
                <div className="input-field col s12">
                  <label htmlFor="email">Feedback</label>
                  <textarea
                    id="feedback"
                    className="validate materialize-textarea"
                    onChange={(e) => setFeedback(e.target.value)}
                    value={feedback}
                  />
                </div>
              </div>
              <button type="submit" className="btn black mt2" onClick={onSubmitClick}>Submit</button>
            </form>
          </div>
        )}
    </>
  );
};

export default Feedback;
