import React from 'react';

const Dashboard = () => (
  <>

    <div className="row">
      <h3 className="header">Admin Dashboard</h3>

      <div className="col s6 m6">
        <div className="card horizontal">
          <div className="card-image">
            <img src="https://lorempixel.com/100/190/nature/6" alt="" />
          </div>
          <div className="card-stacked">
            <div className="card-content">
              <h4>Doctors</h4>
              <p>Information about doctors, their statuses and patients.</p>
            </div>
            <div className="card-action">
              <a href="/admin/doctors">Open</a>
            </div>
          </div>
        </div>
      </div>

      <div className="col s6 m6">
        <div className="card horizontal">
          <div className="card-image">
            <img src="https://lorempixel.com/100/190/nature/6" alt="" />
          </div>
          <div className="card-stacked">
            <div className="card-content">
              <h4>Analytics</h4>
              <p>Aggregated information about doctors, patients and system.</p>
            </div>
            <div className="card-action">
              <a href="/admin/analytics">Open</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default Dashboard;
