/* eslint-disable max-len */
import M from '@materializecss/materialize';
import React from 'react';

const Help = () => {
  React.useEffect(() => {
    M.AutoInit();
  });

  return (
    <>
      <div className="row">
        <div className="col s12">
          <div className="card horizontal">
            <div className="card-stacked">
              <div className="card-content">
                <p>
                  Please contact&nbsp;
                  <a href="tel:855-220-1213">855-220-1213</a>
                &nbsp;or&nbsp;
                  <a href="tel:239-323-0895">239-323-0895</a>
                &nbsp;for assistance with the provider portal, patient application, or devices (9 AM EST - 9 PM EST)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <ul className="tabs">
            <li className="tab col s3"><a className="active" href="#faq">FAQ</a></li>
            <li className="tab col s3"><a href="#product-information">Product Information</a></li>
          </ul>
        </div>
        <div id="faq" className="col s12">
          <ul className="collapsible">
            <li>
              <div className="collapsible-header">
                <i className="material-icons">add</i>
                How do I create an account?
              </div>
              <div className="collapsible-body">
                <ul className="unordered-list browser-default">
                  <li>You can create a provider account either on the mobile application (Vive Precision) or on the viverpm website (viverpm.com)</li>
                  <li>Begin by going to viverpm.com.  You can then click the Register button on the main screen, or click the Register button at the top right</li>
                  <li>Once there, sign up with your first name, last name, gender, your email address, and a password</li>
                </ul>
              </div>
            </li>

            <li>
              <div className="collapsible-header">
                <i className="material-icons">add</i>
                How do I add a patient?
              </div>
              <div className="collapsible-body">
                <ul className="unordered-list browser-default">
                  <li>You can add a patient by clicking Requests once you are logged in.  You now have two options, the patient sends you a request, or you can send a request to the patient</li>
                  <li>If you would like to send the request, go to the Requests tab, and choose Send a New Request.  You can search for a patient by their email address or first and last name.  Please note that the patient must have a Vive Precision account and have enabled the remote patient monitoring features in order for them to receive the request.  Once you’ve sent a request, the patient will then accept the request to begin sharing their data</li>
                  <li>If you would like the patient to initiate the request, they can do so on the Vive Precision app.  Please note that they must enable the remote patient monitoring settings on their app before they can send a request.  On the app, they will go to the Requests section and search for the provider.  Once they’ve sent the request, the provider must accept the request on the Requests section of the app or the Viverpm website.</li>
                </ul>
              </div>
            </li>

            <li>
              <div className="collapsible-header">
                <i className="material-icons">add</i>
                How do I view patient data?
              </div>
              <div className="collapsible-body">
                <ul className="unordered-list browser-default">
                  <li>Once a patient has connected with a provider, their data is automatically viewable if they have taken and transferred a measurement to the app.  If they have, the provider can click on the Open button on the website to view the patient information</li>
                  <li>
                    At the bottom of the page are two tabs: BPM and ENTRIES
                    <ul className="browser-default">
                      <li>BPM displays a graphical representation of the patient’s data over the selected time range.  The time range can be adjusted by choosing from the buttons Day, Week, Month, etc.).</li>
                      <li>ENTRIES displays all of the individual measurements for the desired time range.</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>

            <li>
              <div className="collapsible-header">
                <i className="material-icons">add</i>
                Where can I purchase additional devices?
              </div>
              <div className="collapsible-body">
                <ul className="unordered-list browser-default">
                  <li>
                    You can buy devices that allow to transfer measurements to the App on&nbsp;
                    <a href="https://portal.vivehealth.com/web/login" target="_blank" rel="noreferrer">ViveHealth Business Portal</a>
                    .
                  </li>
                </ul>
              </div>
            </li>

          </ul>
        </div>
        <div id="product-information" className="col s12">
          <ul className="collapsible">
            <li>
              <div className="collapsible-header">
                <i className="material-icons">add</i>
                Blood Pressure Monitor
              </div>
              <div className="collapsible-body">
                <div className="video-wrapper">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/Q0tU1YbYzw0"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>

                <a className="mt2 d-block" href="/manuals/bpm-manual-bt-s.pdf" target="_blank">
                  <i className="tiny material-icons pr1">file_download</i>
                  Blood Pressure Monitor Manual (Model BT-S)
                </a>
                <a className="mt2 d-block" href="/manuals/bpm-manual-bt-v.pdf" target="_blank">
                  <i className="tiny material-icons pr1">file_download</i>
                  Blood Pressure Monitor Manual (Model BT-V)
                </a>
                <a className="mt2 d-block" href="/manuals/compact-bpm-manual-bt-s.pdf" target="_blank">
                  <i className="tiny material-icons pr1">file_download</i>
                  Compact Blood Pressure Monitor Manual (Model BT-S)
                </a>
              </div>
            </li>

            <li>
              <div className="collapsible-header">
                <i className="material-icons">add</i>
                Body Fat Scale
              </div>
              <div className="collapsible-body">
                <div className="video-wrapper">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/zscwoa8ENc8"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              </div>
            </li>

            <li>
              <div className="collapsible-header">
                <i className="material-icons">add</i>
                Digital Scale
              </div>
              <div className="collapsible-body">
                <div className="video-wrapper">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/QBt8UPTNW5M"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>

                <a className="mt2 d-block" href="/manuals/scales-manual.pdf" target="_blank">
                  <i className="tiny material-icons pr1">file_download</i>
                  Digital Scales Manual
                </a>
              </div>
            </li>

            <li>
              <div className="collapsible-header">
                <i className="material-icons">add</i>
                ECG
              </div>
              <div className="collapsible-body">
                <div className="video-wrapper">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/SHcVr8xucOI"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>

                <div className="video-wrapper mt4">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/XDywI1DJij0"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>

                <div className="video-wrapper mt4">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/WDc9fceyRKU"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>

                <a className="mt2 d-block" href="/manuals/ecg-manual.pdf" target="_blank">
                  <i className="tiny material-icons pr1">file_download</i>
                  ECG Manual
                </a>
              </div>
            </li>

            <li>
              <div className="collapsible-header">
                <i className="material-icons">add</i>
                Heart Rate Scale
              </div>
              <div className="collapsible-body">
                <div className="video-wrapper">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/T_noe1b1ztU"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              </div>
            </li>

            <li>
              <div className="collapsible-header">
                <i className="material-icons">add</i>
                Marble Scale
              </div>
              <div className="collapsible-body">
                <div className="video-wrapper">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/XQfwLBrOkAs"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              </div>
            </li>

            <li>
              <div className="collapsible-header">
                <i className="material-icons">add</i>
                Pulse Oximeter
              </div>
              <div className="collapsible-body">
                <div className="video-wrapper">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/mI4eAM89vJY"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>

                <div className="video-wrapper mt4">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/dKQcK8BF_Pw"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>

                <a className="mt2 d-block" href="/manuals/pulse-oximeter-manual-bt-s.pdf" target="_blank">
                  <i className="tiny material-icons pr1">file_download</i>
                  Pulse Oximeter Manual (Model BT-S)
                </a>
              </div>
            </li>

            <li>
              <div className="collapsible-header">
                <i className="material-icons">add</i>
                Glucose Monitor
              </div>
              <div className="collapsible-body">
                <div className="video-wrapper">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/5EQdQBTzhe4"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

    </>
  );
};

export default Help;
