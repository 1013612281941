import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import RangeButtonsGroup from '../DetailsButtons';
import SummaryTable from './SummaryTable';
import SpentTimeSummary from './SpentTimeSummary';

const Summary = ({ patient }) => {
  const [startDate, setStartDate] = React.useState(moment().toDate());
  const [endDate, setEndDate] = React.useState(moment().toDate());

  const handleIntervalChanges = ({ startDate, endDate }) => {
    if (startDate) setStartDate(startDate);
    if (endDate) setEndDate(endDate);
  };

  return (
    <>
      <RangeButtonsGroup
        defaultInterval="monthly"
        callback={handleIntervalChanges}
      />

      <SummaryTable patient={patient} startDate={startDate} endDate={endDate} />

      <SpentTimeSummary patientId={patient.id} startDate={startDate} endDate={endDate} />
    </>
  );
};

Summary.propTypes = {
  patient: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default Summary;
