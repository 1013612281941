import M from '@materializecss/materialize';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import LongContent from '../../Components/LongContent';
import Table from '../../Components/Table';

import { reducer, sendRequest } from '../../utils';

const CreatedAt = ({ row: { original: cell } }) => (
  <div>{moment.utc(cell.created_at, 'YYYY-MM-DD h:mm:ss').local().format('LLL')}</div>
);

CreatedAt.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

const Notes = ({ patientId, callbackNotes }) => {
  const [newNote, setNewNote] = React.useState('');
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', reload: false, isLoading: false, isError: false,
    },
  );

  React.useEffect(() => {
    M.updateTextFields();

    // Resize <textarea> elements
    setTimeout(
      () => {
        document.querySelectorAll('textarea').forEach(
          (textarea) => M.textareaAutoResize(textarea),
        );
      }, 250,
    );
  });

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('get_notes', 'GET', { patient_id: patientId })
      .then((resp) => {
        const notes = resp.Notes;
        // send notes back to parent
        callbackNotes(notes);
        dispatch({
          type: 'FETCH_SUCCESS',
          payload: notes,
          reload: false,
        });
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
      });
  }, [state.reload]);

  const onAddNewNote = (e) => {
    e.preventDefault();

    dispatch({ type: 'FETCH_INIT' });

    sendRequest('create_note', 'POST', { patient_id: patientId, content: newNote })
      .then((resp) => {
        if (resp.status === 'Success') {
          dispatch({
            type: 'FETCH_SUCCESS',
            // payload: resp.Notes,
            reload: true,
          });

          // Reset new note content
          setNewNote('');
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: resp.message });
        }
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
      });
  };

  const NoteContent = ({ row: { original: cell } }) => (
    <LongContent content={cell.content} />
  );

  NoteContent.propTypes = {
    row: PropTypes.shape({
      original: PropTypes.shape({
        content: PropTypes.string.isRequired,
      }),
    }).isRequired,
  };
  const columns = [
    {
      Header: 'Note',
      accessor: 'content',
      Cell: NoteContent,
    },
    {
      Header: 'Created On',
      Cell: CreatedAt,
      accessor: 'created_at',
    },
  ];

  return (
    <>
      <h5 className="mt4 mb4">Notes</h5>

      {state.isError && <p className="form-errors">{state.error}</p>}

      <div className="row">
        <div className="input-field col s12">
          <textarea
            id="new-note"
            name="new-note"
            className="materialize-textarea validate"
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
          />
          <label htmlFor="new-note">Add a new note</label>
        </div>
        <div className="col s12">
          <button type="button" className="btn black" onClick={onAddNewNote}>Add</button>
        </div>
      </div>

      {state.data.length > 0
        ? (
          <Table columns={columns} data={state.data} centered defaultPageSize={10} defaultSortBy="created_at" defaultSortDesc />
        )
        : (
          <p className="form-messages">No notes found</p>
        )}
    </>
  );
};

Notes.propTypes = {
  patientId: PropTypes.string.isRequired,
  callbackNotes: PropTypes.func.isRequired,
};

export default Notes;
