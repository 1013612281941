import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import Loader from '../Components/Loader';
import { reducer, sendRequest } from '../utils';
import Table from '../Components/Table';

const ChangeLogs = () => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isLoading: true, isError: false,
    },
  );

  const handleFetchChangelogs = React.useCallback(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('change_logs', 'GET')
      .then((response) => {
        if (response.status === 'Failure') {
          dispatch({ type: 'FETCH_FAILURE', error: response.errors });
          return;
        }

        // Preprocess requests
        const changelogs = response.change_logs.map(
          (l) => ({
            id: l.id,
            version: l.version,
            date_released: moment(new Date(l.date_released)).format('MMMM DD, YYYY'),
            change_notes: l.change_notes,
            module: l.module,
          }),
        );

        dispatch({
          type: 'FETCH_SUCCESS',
          payload: changelogs,
        });
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
      });
  }, []);

  React.useEffect(() => {
    handleFetchChangelogs();
  }, [handleFetchChangelogs]);

  const handleDelete = (changelogId) => {
    sendRequest(`change_logs/${changelogId}`, 'DELETE')
      .then((response) => {
        if (response.status === 'Success') {
          handleFetchChangelogs();
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.errors });
        }
      });
  };

  return (
    <>
      <h4>What&apos;s New</h4>
      <div className="row">
        <div className="col s12">
          <Link to="/changelogs/add" className="btn black mb2">Add a new Change Log</Link>

          {state.isError && <p className="form-errors">{state.error}</p>}
          {state.message && <p className="form-messages">{state.message}</p>}

          {state.isLoading ? (
            <Loader />
          ) : (
            <>
              {state.data.length
                ? (
                  <ChangelogsTable
                    requests={state.data}
                    actions={[
                      {
                        label: 'Edit',
                        href: (changelogId) => `/changelogs/${changelogId}`,
                      },
                      {
                        label: 'Delete',
                        callback: handleDelete,
                        color: 'red',
                      },
                    ]}
                  />
                )
                : <p>You have no pending requests</p>}

            </>
          )}
        </div>
      </div>
    </>
  );
};

const ChangelogsTable = ({ requests, actions }) => {
  const RequestActions = ({ row: { original: cell } }) => (
    <div>
      {
        actions.map((action) => (
          action.href ? (
            <Link
              key={action.label}
              to={action.href(cell.id)}
              className={`btn mr2 mb2 ${action.color || 'black'}`}
            >
              {action.label}
            </Link>
          ) : (
            <a
              key={action.label}
              className={`btn mr2 mb2 ${action.color || 'black'}`}
              href="#/"
              onClick={() => action.callback(cell.id)}
            >
              {action.label}
            </a>
          )
        ))
      }
    </div>
  );

  RequestActions.propTypes = {
    row: PropTypes.shape({
      original: PropTypes.shape({
        id: PropTypes.string,
      }),
    }).isRequired,
  };

  const columns = [
    {
      Header: 'Version',
      accessor: 'version',
    },
    {
      Header: 'Date Released',
      accessor: 'date_released',
    },
    {
      Header: 'Change Notes',
      accessor: 'change_notes',
    },
    {
      Header: 'Module',
      accessor: 'module',
    },
    {
      Header: 'Actions',
      Cell: RequestActions,
    },
  ];

  return (
    <Table columns={columns} data={requests} centered />
  );
};

ChangelogsTable.propTypes = {
  requests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      version: PropTypes.string.isRequired,
      date_released: PropTypes.string.isRequired,
      change_notes: PropTypes.string.isRequired,
      module: PropTypes.string.isRequired,
    }),
  ).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      callback: PropTypes.func,
      color: PropTypes.string,
    }),
  ).isRequired,
};

export default ChangeLogs;
