import moment from 'moment';
import Plot from 'react-plotly.js';
import React from 'react';
import PropTypes from 'prop-types';
import {
  calculateAverageForKey, reducer, round, sendRequest,
} from '../../utils';
import NoData from '../../Components/NoData';
import Select from '../../Components/Select';
import Loader from '../../Components/Loader';

const ECGPlot = ({
  patient, startDate, endDate, isActive,
}) => {
  const [date, setDate] = React.useState(null);
  const [dates, setDates] = React.useState([]);

  const [time, setTime] = React.useState(null);
  const [times, setTimes] = React.useState([]);

  const [plotData, setPlotData] = React.useState([]);

  const [state, dispatch] = React.useReducer(
    reducer,
    { data: [], isLoading: true, isError: false },
  );

  React.useEffect(() => {
    if (!isActive) {
      // Do not load anything while not active
      return;
    }

    dispatch({ type: 'FETCH_INIT' });

    const opts = {
      entry_type: 'Pulse',
      date_from: moment(startDate).format().slice(0, 10),
      date_to: moment(endDate).format().slice(0, 10),
      user_id: patient.id,
    };

    sendRequest('get_manual_entries_by_user_id', 'GET', opts)
      .then((response) => {
        if (response.status === 'Failure') {
          dispatch({
            type: 'FETCH_FAILURE',
            error: response.errors,
            payload: [],
          });
          return;
        }

        dispatch({
          type: 'FETCH_SUCCESS',
          payload: response.entry_data,
        });
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
      });
  }, [startDate, endDate, isActive]);

  React.useEffect(() => {
    const dates = state.data.map((e) => e.date_for_update);

    setDates(dates);
    setDate(dates[0]);
  }, [state.data]);

  React.useEffect(() => {
    // When date changed - update time options
    const rawData = state.data.filter((i) => i.date_for_update === date);

    const times = rawData.map((i) => i.time);

    setTime(times[0]);
    setTimes(times);
  }, [date]);

  React.useEffect(() => {
    // Update graph
    if (state.data.length) {
      if (!date || !time) {
        // nothing to render
        setPlotData([]);
        return;
      }

      const rawData = state.data.filter((i) => i.date_for_update === date && i.time === time)[0];

      if (!rawData) {
        // no data found
        setPlotData([]);
        return;
      }
      try {
        const y = JSON.parse(rawData.ecg_data);
        const x = Array.from(Array(y.length).keys());

        const plot = [
          {
            x,
            y,
            type: 'scatter',
            mode: 'lines',
            marker: {
              color: '#f44336',
            },
            name: 'ECG',
          },
        ];
        setPlotData(plot);
      } catch (error) {
        // no data found
        setPlotData([]);
      }
    }
  }, [date, time]);

  React.useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [isActive]);

  const averagePulse = round(calculateAverageForKey(state.data, 'pulse'));

  if (state.isLoading) {
    return (
      <Loader />
    );
  }

  if (!state.data.length) {
    return (
      <NoData />
    );
  }

  const minValue = Math.min(...plotData[0]?.y || []);
  const maxValue = Math.max(...plotData[0]?.y || []);
  const mediumValue = round((minValue + maxValue) / 2);

  return (
    <div className="row">
      <div className="col s12">
        <div className="input-field col s6 m6 hide-on-print">
          <Select
            name="date"
            label="ECG Date"
            values={dates.reduce((acc, item) => { acc[item] = item; return acc; }, {})}
            defaultValue={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
        <div className="input-field col s6 m6 hide-on-print">
          <Select
            name="time"
            label="ECG Time"
            values={times.reduce((acc, item) => { acc[item] = item; return acc; }, {})}
            defaultValue={time}
            onChange={(e) => setTime(e.target.value)}
          />
        </div>
      </div>

      <div className="col s12">
        {
        !plotData.length
          ? <h5 className="text-center pt2 pb2">Please select date and time</h5>
          : (
            <>
              <Plot
                data={plotData}
                style={{ width: '100%' }}
                layout={{
                  legend: { x: 0.01, y: -1, orientation: 'h' },
                  margin: {
                    l: 60, r: 20, b: 50, t: 50, pad: 2,
                  },
                  autosize: true,
                  yaxis: {
                    title: 'ECG',
                    nticks: 3,
                    tickformat: 'd',
                    tickvals: [minValue, mediumValue, maxValue],
                  },
                  xaxis: {
                    title: 'Time',
                  },
                }}
                config={{
                  displayModeBar: false,
                }}
              />
              <p className="block text-center">
                <small>
                  <strong>
                    This graph shows the latest measurement of ECG (
                    {date}
                    {' '}
                    {time}
                    ). Please, select the single day in filters to view the ECG for the specific day
                  </strong>
                </small>
              </p>
            </>
          )
      }
      </div>

      <div className="col s12 m6 offset-m3">
        <div className="card blue-grey">
          <div className="card-content white-text">
            <span className="card-title text-center">Average Pulse</span>
            <div className="center-align">
              {
                state.data.length
                  ? (
                    <>
                      <h4>{averagePulse}</h4>
                      BPM
                    </>
                  )
                  : <h4>Not Enough Data</h4>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ECGPlot.propTypes = {
  patient: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default ECGPlot;
