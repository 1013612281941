import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import M from '@materializecss/materialize';
// import { CSVLink } from 'react-csv';
import Loader from '../Components/Loader';
import Table from '../Components/Table';
import { reducer, sendRequest } from '../utils';
import Select from '../Components/Select';

const STATUSES = {
  proof_of_concept: 'Proof of Concept',
  free_trial: 'Free Trial',
  customer: 'Customer',
  frozen: 'Frozen',
};

// Used array to save the order of the values
const TRIAL_DURATIONS = [
  ['', ''],
  [30, '30 days'],
  [60, '60 days'],
  [90, '90 days'],
];

const SALES_REPS = [
  ['', ''],
  ['Aaron', 'Aaron'],
  ['Fred', 'Fred'],
  ['Dani', 'Dani'],
  ['Sam', 'Sam'],
  ['other', 'Other / Test'],
];

const DoctorStatus = ({ row: { original: cell } }) => {
  const onStatusChange = (e) => {
    const newStatus = e.target.value;

    // Ask for confirmation before freezing a doctor
    if (newStatus === 'frozen' && !window.confirm('Are you sure you want to freeze this doctor?')) {
      e.target.value = cell.status;
      M.FormSelect.init(e.target);
      return;
    }

    sendRequest('update_user_by_id', 'PUT', { id: cell.id, status: newStatus })
      .then((resp) => {
        if (resp.status === 'Success') {
          alert(resp.message);
        } else {
          alert(`Something went wrong. Error: ${resp.error}`);
        }
      })
      .catch(() => {
        alert('Something went wrong. Please try again later.');
      });
  };

  return (
    <div>
      <Select
        name="status"
        label="Status"
        values={STATUSES}
        defaultValue={cell.status}
        onChange={onStatusChange}
      />
    </div>
  );
};

DoctorStatus.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

const DoctorSalesRep = ({ row: { original: cell } }) => {
  const onSalesRepChange = (e) => {
    const newSalesRep = e.target.value;

    sendRequest('update_user_by_id', 'PUT', { id: cell.id, sales_rep: newSalesRep })
      .then((resp) => {
        if (resp.status === 'Success') {
          alert(resp.message);
        } else {
          alert(`Something went wrong. Error: ${resp.error}`);
        }
      })
      .catch(() => {
        alert('Something went wrong. Please try again later.');
      });
  };

  return (
    <div>
      <Select
        name="sales_rep"
        label="Sales Rep"
        values={SALES_REPS}
        defaultValue={cell.sales_rep}
        onChange={onSalesRepChange}
      />
    </div>
  );
};

DoctorSalesRep.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      sales_rep: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

const DoctorTrialDuration = ({ row: { original: cell } }) => {
  const onTrialDurationChange = (e) => {
    const newTrialDuration = e.target.value;

    sendRequest('update_user_by_id', 'PUT', { id: cell.id, trial_duration: newTrialDuration })
      .then((resp) => {
        if (resp.status === 'Success') {
          alert(resp.message);
        } else {
          alert(`Something went wrong. Error: ${resp.error}`);
        }
      })
      .catch(() => {
        alert('Something went wrong. Please try again later.');
      });
  };

  return (
    <div>
      <Select
        name="trial_duration"
        label="Trial Duration"
        values={TRIAL_DURATIONS}
        defaultValue={cell.trial_duration}
        onChange={onTrialDurationChange}
      />
    </div>
  );
};

DoctorTrialDuration.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      trial_duration: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

const CreatedAt = ({ row: { original: cell } }) => (
  <div>{moment.utc(cell.created_at, 'YYYY-MM-DD h:mm:ss').local().format('L')}</div>
);

CreatedAt.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

const LastSignInAt = ({ row: { original: cell } }) => (
  <div>
    {
      cell.last_sign_in_at
        ? moment.utc(cell.last_sign_in_at, 'YYYY-MM-DD h:mm:ss').local().format('L')
        : 'No date'
    }
  </div>
);

LastSignInAt.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      last_sign_in_at: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

const FirstPatientAddedDate = ({ row: { original: cell } }) => (
  <div>
    {
      cell.date_first_patient_added
        ? moment.utc(cell.date_first_patient_added, 'YYYY-MM-DD h:mm:ss').local().format('L')
        : 'No date'
    }
  </div>
);

FirstPatientAddedDate.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      date_first_patient_added: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

const LastPatientAddedDate = ({ row: { original: cell } }) => (
  <div>
    {
      cell.date_last_patient_added
        ? moment.utc(cell.date_last_patient_added, 'YYYY-MM-DD h:mm:ss').local().format('L')
        : 'No date'
    }
  </div>
);

LastPatientAddedDate.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      date_last_patient_added: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

const TimeUsing = ({ row: { original: cell } }) => (
  <div>{moment.utc(cell.created_at, 'YYYY-MM-DD h:mm:ss').fromNow(true)}</div>
);

TimeUsing.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

const DoctorsTable = ({ doctors, actions }) => {
  const DoctorActions = ({ row: { original: cell } }) => (
    <div>
      {actions.map((action) => (
        <a
          key={action.label}
          className={`btn mr2 mb2 ${action.color || 'black'}`}
          href="#/"
          onClick={() => action.callback(cell)}
        >
          {action.label}
        </a>
      ))}
    </div>
  );

  DoctorActions.propTypes = {
    row: PropTypes.shape({
      original: PropTypes.shape({
        id: PropTypes.string,
      }),
    }).isRequired,
  };

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Doctor Name',
      accessor: 'plain_name',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Sales Rep',
      Cell: DoctorSalesRep,
      accessor: 'sales_rep',
      style: { minWidth: '150px' },
    },
    {
      Header: 'Status',
      Cell: DoctorStatus,
      accessor: 'status',
      style: { minWidth: '150px' },
    },
    {
      Header: 'Time Using',
      accessor: 'time_using',
      Cell: TimeUsing,
    },
    {
      Header: 'Trial Duration',
      Cell: DoctorTrialDuration,
      accessor: 'trial_duration',
    },
    {
      Header: 'Date First Patient Added',
      accessor: 'date_first_patient_added',
      Cell: FirstPatientAddedDate,
    },
    {
      Header: 'Date Last Patient Added',
      accessor: 'date_last_patient_added',
      Cell: LastPatientAddedDate,
    },
    {
      Header: 'Created At',
      accessor: 'created_at',
      Cell: CreatedAt,
    },
    {
      Header: 'Last Sign In At',
      accessor: 'last_sign_in_at',
      Cell: LastSignInAt,
    },
    {
      Header: 'Number Of Patients',
      accessor: 'number_of_patients',
    },
    {
      Header: 'Actions',
      Cell: DoctorActions,
    },
  ];

  return (
    <Table
      classes="doctors-table"
      columns={columns}
      data={doctors}
      withSearch
      withDownloadButton
      centered
      responsive
      hiddenColumns={['id', 'email', 'plain_name']}
      excludeFromExport={['name']}
    />
  );
};

DoctorsTable.propTypes = {
  doctors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    name: PropTypes.object.isRequired,
  })).isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    callback: PropTypes.func.isRequired,
    color: PropTypes.string,
  })).isRequired,
};

const DoctorsDashboard = () => {
  const [state, dispatch] = React.useReducer(
    reducer,
    { data: [], isLoading: true, isError: false },
  );

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('admin/get_doctors', 'GET')
      .then((response) => {
        const doctors = response.doctors.map((doctor) => ({
          ...doctor,
          plain_name: `${doctor.first_name} ${doctor.last_name}`,
          name: (
            <>
              { `${doctor.first_name} ${doctor.last_name}` }
              <br />
              <small>{doctor.email}</small>
            </>
          ),
          name_raw: `${doctor.first_name} ${doctor.last_name}`,
          time_using: moment.duration(moment.utc() - moment.utc(doctor.created_at, 'YYYY-MM-DD h:mm:ss')),
        }));

        dispatch({
          type: 'FETCH_SUCCESS',
          payload: doctors,
        });
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error });
      });
  }, []);

  const handleDeleteDoctor = (doctor) => {
    // Ask for confirmation
    if (!window.confirm(`Are you sure you want to delete "${doctor.first_name} ${doctor.last_name}" account?`)) {
      return;
    }

    // Send request to delete doctor
    sendRequest('admin/delete_doctor_account', 'DELETE', { id: doctor.id })
      .then(() => {
        const newDoctors = state.data.filter((d) => d.id !== doctor.id);

        dispatch({
          type: 'FETCH_SUCCESS',
          payload: newDoctors,
          message: `${doctor.first_name} ${doctor.last_name} account deleted successfully`,
        });
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error });
      });
  };

  return (
    <>
      <a href="/admin/" className="btn btn-small white black-text">
        <i className="material-icons left">arrow_back</i>
        Go Back to Admin Dashboard
      </a>

      <h4>Doctors</h4>

      <div>
        {state.isError && <p>Something went wrong ...</p>}

        {state.message && <p className="form-messages">{state.message}</p>}

        {state.isLoading ? (
          <Loader />
        ) : (
          <DoctorsTable
            doctors={state.data}
            actions={[
              {
                label: 'Delete',
                callback: handleDeleteDoctor,
                color: 'red',
              },
            ]}
          />
          // <Table
          //   classes="doctors-table"
          //   columns={columns}
          //   data={data}
          //   withSearch
          //   withDownloadButton
          //   centered
          //   responsive
          //   hiddenColumns={['id', 'email', 'plain_name']}
          //   excludeFromExport={['name']}
          // />
        )}
      </div>
    </>
  );
};

export default DoctorsDashboard;
