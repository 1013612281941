import PropTypes from 'prop-types';
import React from 'react';

const LongContent = ({ content }) => {
  const [collapsed, setCollapsed] = React.useState(true);

  if (content.length > 255) {
    if (collapsed) {
      return (
        <div className="note-content text-pre-wrap">
          {`${content.substring(0, 255)}...`}
          <button type="button" className="btn-link white black-text ml1" onClick={() => setCollapsed(false)}>Show more</button>
        </div>
      );
    }

    return (
      <div className="note-content text-pre-wrap">
        {content}
        <button type="button" className="btn-link white black-text ml1" onClick={() => setCollapsed(true)}>Hide</button>
      </div>
    );
  }

  return <div className="text-pre-wrap">{content}</div>;
};

LongContent.propTypes = {
  content: PropTypes.string.isRequired,
};

export default LongContent;
