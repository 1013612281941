import M from '@materializecss/materialize';
import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import ReactToPrint from 'react-to-print';
import BloodPressurePlot from './Data/BloodPressure';
import PatientEntries from './Data/Entries';
import PatientDetailsPDF from './DetailsPDF';
import RangeButtonsGroup from './DetailsButtons';
import PulsePlot from './Data/Pulse';
import WeightPlot from './Data/Weight';
import ECGPlot from './Data/ECG';
import Select from '../Components/Select';
import TemperaturePlot from './Data/Temperature';
import GlucosePlot from './Data/Glucose';

const EXPORT_OPTIONS = {
  bpm: 'Blood Pressure',
  pulse: 'Pulse & SpO2',
  weight: 'Weight',
  ecg: 'ECG',
  temperature: 'Temperature',
  bgm: 'Blood Glucose',
  entries: 'Entries',
  time_spent: 'Time Spent',
  care_plan: 'Care Plan',
};

const PatientDetails = ({ patient, active }) => {
  const componentRef = React.useRef();

  const [startDate, setStartDate] = React.useState(moment().subtract(1, 'months').toDate());
  const [endDate, setEndDate] = React.useState(moment().toDate());

  const [activeTab, setActiveTab] = React.useState('blood-pressure');
  const [exportItems, setExportItems] = React.useState(Object.keys(EXPORT_OPTIONS));

  const handleIntervalChanges = ({ startDate, endDate }) => {
    if (startDate) setStartDate(startDate);
    if (endDate) setEndDate(endDate);
  };

  const handleExportItemsChange = React.useCallback((e) => {
    const newTabs = Array.from(e.target.selectedOptions).map((o) => o.value);
    setExportItems(newTabs);
  });

  React.useEffect(() => {
    M.Tabs.init(document.querySelector('.details-tabs'), {});
  });

  const documentTitle = `${patient.full_name} (${moment(startDate).format('l').slice(0, 10)} - ${moment(endDate).format('l').slice(0, 10)})`;

  if (!active) {
    return <></>;
  }

  return (
    <div>
      <h5 className="header-with-button mt4">
        Details
      </h5>
      <div className="row d-flex">
        <div className="input-field col s9">
          <Select
            name="export-values"
            label="Export"
            values={EXPORT_OPTIONS}
            defaultValue={Object.keys(EXPORT_OPTIONS)}
            multiple
            onChange={handleExportItemsChange}
          />
        </div>
        <div className="col s3" style={{ margin: 'auto' }}>
          <ReactToPrint
            trigger={
              () => <button type="button" className="btn btn-small white black-text ml2 right">Export to PDF</button>
            }
            content={() => componentRef.current}
            documentTitle={documentTitle}
          />
        </div>
      </div>

      <RangeButtonsGroup
        defaultInterval="monthly"
        // startDate={startDate}
        // endDate={endDate}
        callback={handleIntervalChanges}
      />

      <>
        <div className="row">
          <div className="col s12">
            <ul className="tabs tabs-fixed-width details-tabs">
              <li className="tab col s3">
                <a className="active" href="#blood-pressure" onClick={() => setActiveTab('blood-pressure')}>
                  Blood Pressure
                </a>
              </li>
              <li className="tab col s3">
                <a href="#pulse" onClick={() => setActiveTab('pulse')}>
                  Pulse & S
                  <span style={{ textTransform: 'lowercase' }}>p</span>
                  O
                  <sub>2</sub>
                </a>
              </li>
              <li className="tab col s3"><a href="#weight" onClick={() => setActiveTab('weight')}>Weight</a></li>
              <li className="tab col s3"><a href="#ecg" onClick={() => setActiveTab('ecg')}>ECG</a></li>
              <li className="tab col s3"><a href="#temperature" onClick={() => setActiveTab('temperature')}>Temperature</a></li>
              <li className="tab col s3"><a href="#bgm" onClick={() => setActiveTab('bgm')}>Blood Glucose</a></li>
              <li className="tab col s3"><a href="#entries" onClick={() => setActiveTab('entries')}>Entries</a></li>
            </ul>
          </div>
          <div id="blood-pressure" className="col s12 mt4">
            <BloodPressurePlot
              patient={patient}
              startDate={startDate}
              endDate={endDate}
              isActive={activeTab === 'blood-pressure'}
            />
          </div>
          <div id="pulse" className="col s12 mt4">
            <PulsePlot
              patient={patient}
              startDate={startDate}
              endDate={endDate}
              isActive={activeTab === 'pulse'}
            />
          </div>
          <div id="weight" className="col s12 mt4">
            <WeightPlot
              patient={patient}
              startDate={startDate}
              endDate={endDate}
              isActive={activeTab === 'weight'}
            />
          </div>
          <div id="ecg" className="col s12 mt4">
            <ECGPlot
              patient={patient}
              startDate={startDate}
              endDate={endDate}
              isActive={activeTab === 'ecg'}
            />
          </div>
          <div id="temperature" className="col s12 mt4">
            <TemperaturePlot
              patient={patient}
              startDate={startDate}
              endDate={endDate}
              isActive={activeTab === 'temperature'}
            />
          </div>
          <div id="bgm" className="col s12 mt4">
            <GlucosePlot
              patient={patient}
              startDate={startDate}
              endDate={endDate}
              isActive={activeTab === 'bgm'}
            />
          </div>
          <div id="entries" className="col s12 mt4">
            <PatientEntries
              patient={patient}
              startDate={startDate}
              endDate={endDate}
              isActive={activeTab === 'entries'}
              withDownloadButton
            />
          </div>
        </div>
        <div style={{ display: 'none' }}>
          <div ref={componentRef}>
            <PatientDetailsPDF
              patient={patient}
              startDate={startDate}
              endDate={endDate}
              items={exportItems}
            />
          </div>
        </div>
      </>
    </div>
  );
};

PatientDetails.propTypes = {
  patient: PropTypes.shape({
    id: PropTypes.string.isRequired,
    full_name: PropTypes.string.isRequired,
  }).isRequired,
  active: PropTypes.bool.isRequired,
};

export default PatientDetails;
