import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  calculateAverageForKey, reducer, round, sendRequest,
} from '../../utils';
import Loader from '../../Components/Loader';

const ENTRY_TYPES = ['BPM', 'Pulse', 'Weight', 'PulseOximeter', 'Thermometer', 'BGM'];

const SummaryTable = ({ patient, startDate, endDate }) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    { data: null, isLoading: false, isError: false },
  );

  const fetchEntries = () => {
    dispatch({ type: 'FETCH_INIT' });

    const opts = {
      date_from: moment(startDate).format().slice(0, 10),
      date_to: moment(endDate).format().slice(0, 10),
      user_id: patient.id,
    };

    const promises = ENTRY_TYPES.map(
      (type) => sendRequest('get_manual_entries_by_user_id', 'GET', { ...opts, entry_type: type }),
    );

    Promise.all(promises)
      .then((responses) => {
        const failedResponses = responses.filter((r) => r.status !== 'Success');
        if (failedResponses.length === responses.length) {
          dispatch({ type: 'FETCH_FAILURE', error: failedResponses[0].errors });
        } else {
          const data = {};

          responses.forEach((r) => {
            const rawData = r.entry_data || [];

            if (rawData.length) {
              const entryType = rawData[0].entry_type;
              rawData.sort((a, b) => {
                const d1 = moment(a.date_to_date, 'MM/DD/YYYY');
                const d2 = moment(b.date_to_date, 'MM/DD/YYYY');
                if (d1 > d2) {
                  return 1;
                }
                if (d1 < d2) {
                  return -1;
                }
                return 0;
              });

              const firstEntry = rawData[0];
              const lastEntry = rawData[rawData.length - 1];

              data[entryType] = {
                first: firstEntry,
                last: lastEntry,
                entries: rawData,
              };
            }
          });

          dispatch({ type: 'FETCH_SUCCESS', payload: data });
        }
      })
      .catch(() => {
        dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' });
      });
  };

  React.useEffect(() => {
    fetchEntries();
  }, [startDate, endDate]);

  if (state.isLoading) {
    return <Loader />;
  }

  return (
    <div className="pb4">
      <h5>Overview</h5>

      {
      state.data
        ? (
          <table className="centered">
            <thead>
              <tr>
                <th>Vital</th>
                <th>Initial Date</th>
                <th>Initial</th>
                <th>Latest Date</th>
                <th>Latest</th>
                <th>Average</th>
              </tr>
            </thead>
            <tbody>
              {/* BPM */}
              <tr>
                <th className="center">Blood Pressure</th>
                {
                  state.data.BPM
                    ? (
                      <>
                        <td>
                          {
                            state.data.BPM.first.systolic
                              ? state.data.BPM.first.date_for_update
                              : ''
                          }
                        </td>
                        <td>
                          {
                            state.data.BPM.first.systolic
                              ? `${state.data.BPM.first.systolic} / ${state.data.BPM.first.diastolic} mmHg`
                              : ''
                          }
                        </td>
                        <td>
                          {
                            state.data.BPM.last.systolic
                              ? state.data.BPM.last.date_for_update
                              : '-'
                          }
                        </td>
                        <td>
                          {
                            state.data.BPM.last.systolic
                              ? `${state.data.BPM.last.systolic} / ${state.data.BPM.last.diastolic} mmHg`
                              : '-'
                          }
                        </td>
                        <td>
                          {
                            round(calculateAverageForKey(state.data.BPM.entries, 'systolic'))
                              // eslint-disable-next-line max-len
                              ? `${round(calculateAverageForKey(state.data.BPM.entries, 'systolic'))} / ${round(calculateAverageForKey(state.data.BPM.entries, 'diastolic'))} mmHg`
                              : '-'
                          }
                        </td>
                      </>
                    )
                    : (
                      <td colSpan="5">
                        <span className="text-center">Not enough data</span>
                      </td>
                    )
                }
              </tr>
              {/* Pulse */}
              <tr>
                <th className="center">Pulse</th>
                {
                  state.data.PulseOximeter
                    ? (
                      <>
                        <td>
                          {
                            state.data.PulseOximeter.first.pulse_oxi
                              ? state.data.PulseOximeter.first.date_for_update
                              : '-'
                          }
                        </td>
                        <td>
                          {
                            state.data.PulseOximeter.first.pulse_oxi
                              ? `${state.data.PulseOximeter.first.pulse_oxi} BPM`
                              : '-'
                          }
                        </td>
                        <td>
                          {
                            state.data.PulseOximeter.last.pulse_oxi
                              ? state.data.PulseOximeter.last.date_for_update
                              : '-'
                          }
                        </td>
                        <td>
                          {
                            state.data.PulseOximeter.last.pulse_oxi
                              ? `${state.data.PulseOximeter.last.pulse_oxi} BPM`
                              : '-'
                          }
                        </td>
                        <td>
                          {
                          round(calculateAverageForKey(state.data.PulseOximeter.entries, 'pulse_oxi'))
                            ? `${round(calculateAverageForKey(state.data.PulseOximeter.entries, 'pulse_oxi'))} BPM`
                            : '-'
                          }
                        </td>
                      </>
                    )
                    : (
                      <td colSpan="5">
                        <span className="text-center">Not enough data</span>
                      </td>
                    )
                }
              </tr>
              {/* SpO2 */}
              <tr>
                <th className="center">
                  SpO
                  <sub>2</sub>
                </th>
                {
                  state.data.PulseOximeter
                    ? (
                      <>
                        <td>
                          {
                            state.data.PulseOximeter.first.spo2
                              ? state.data.PulseOximeter.first.date_for_update
                              : '-'
                          }
                        </td>
                        <td>
                          {
                            state.data.PulseOximeter.first.spo2
                              ? `${state.data.PulseOximeter.first.spo2} %`
                              : '-'
                          }
                        </td>
                        <td>
                          {
                            state.data.PulseOximeter.last.spo2
                              ? state.data.PulseOximeter.last.date_for_update
                              : '-'
                          }
                        </td>
                        <td>
                          {
                            state.data.PulseOximeter.last.spo2
                              ? `${state.data.PulseOximeter.last.spo2} %`
                              : '-'
                          }
                        </td>
                        <td>
                          {
                            round(calculateAverageForKey(state.data.PulseOximeter.entries, 'spo2'))
                              ? `${round(calculateAverageForKey(state.data.PulseOximeter.entries, 'spo2'))} %`
                              : '-'
                          }
                        </td>
                      </>
                    )
                    : (
                      <td colSpan="5">
                        <span className="text-center">Not enough data</span>
                      </td>
                    )
                }
              </tr>
              {/* Weight */}
              <tr>
                <th className="center">Weight</th>
                {
                  state.data.Weight
                    ? (
                      <>
                        <td>
                          {
                            state.data.Weight.first.weight
                              ? state.data.Weight.first.date_for_update
                              : '-'
                          }
                        </td>
                        <td>
                          {
                            state.data.Weight.first.weight
                              ? `${state.data.Weight.first.weight} ${state.data.Weight.first.weight_type}`
                              : '-'
                          }
                        </td>
                        <td>
                          {
                            state.data.Weight.last.weight
                              ? state.data.Weight.last.date_for_update
                              : '-'
                          }
                        </td>
                        <td>
                          {
                            state.data.Weight.last.weight
                              ? `${state.data.Weight.last.weight} ${state.data.Weight.last.weight_type}`
                              : '-'
                          }
                        </td>
                        <td>
                          {
                            round(calculateAverageForKey(state.data.Weight.entries, 'weight'))
                              ? `${round(calculateAverageForKey(state.data.Weight.entries, 'weight'))} ${state.data.Weight.last.weight_type}`
                              : '-'
                          }
                        </td>
                      </>
                    )
                    : (
                      <td colSpan="5">
                        <span className="text-center">Not enough data</span>
                      </td>
                    )
                }
              </tr>
              {/* Temperature */}
              <tr>
                <th className="center">Temperature</th>
                {
                  state.data.Thermometer
                    ? (
                      <>
                        <td>
                          {
                            state.data.Thermometer.first.temperature
                              ? state.data.Thermometer.first.date_for_update
                              : '-'
                          }
                        </td>
                        <td>
                          {
                            state.data.Thermometer.first.temperature
                              ? `${state.data.Thermometer.first.temperature} °C`
                              : '-'
                          }
                        </td>
                        <td>
                          {
                            state.data.Thermometer.last.temperature
                              ? state.data.Thermometer.last.date_for_update
                              : '-'
                          }
                        </td>
                        <td>
                          {
                            state.data.Thermometer.last.temperature
                              ? `${state.data.Thermometer.last.temperature} °C`
                              : '-'
                          }
                        </td>
                        <td>
                          {
                            round(calculateAverageForKey(state.data.Thermometer.entries, 'temperature'))
                              ? `${round(calculateAverageForKey(state.data.Thermometer.entries, 'temperature'))} °C`
                              : '-'
                          }
                        </td>
                      </>
                    )
                    : (
                      <td colSpan="5">
                        <span className="text-center">Not enough data</span>
                      </td>
                    )
                }
              </tr>
              {/* Glucose */}
              <tr>
                <th className="center">Blood Glucose</th>
                {
                  state.data.BGM
                    ? (
                      <>
                        <td>
                          {
                            state.data.BGM.first.bgm
                              ? state.data.BGM.first.date_for_update
                              : '-'
                          }
                        </td>
                        <td>
                          {
                            state.data.BGM.first.bgm
                              ? `${state.data.BGM.first.bgm} mg/dL`
                              : '-'
                          }
                        </td>
                        <td>
                          {
                            state.data.BGM.last.bgm
                              ? state.data.BGM.last.date_for_update
                              : '-'
                          }
                        </td>
                        <td>
                          {
                            state.data.BGM.last.bgm
                              ? `${state.data.BGM.last.bgm} mg/dL`
                              : '-'
                          }
                        </td>
                        <td>
                          {
                            round(calculateAverageForKey(state.data.BGM.entries, 'bgm'))
                              ? `${round(calculateAverageForKey(state.data.BGM.entries, 'bgm'))} mg/dL`
                              : '-'
                          }
                        </td>
                      </>
                    )
                    : (
                      <td colSpan="5">
                        <span className="text-center">Not enough data</span>
                      </td>
                    )
                }
              </tr>
            </tbody>
          </table>
        )
        : <h5 className="text-center pt2 pb2">{state.error}</h5>
      }
    </div>
  );
};

SummaryTable.propTypes = {
  patient: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
};

export default SummaryTable;
