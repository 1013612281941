import React from 'react';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';

import Reminders from './Reminders/List';
import Notes from './Notes';
import Actions from './Actions';
import CarePlanPDFView from './CarePlanPDFView';

const CarePlan = ({ patient }) => {
  const componentRef = React.useRef();
  const [notes, setNotes] = React.useState([]);
  const [actions, setActions] = React.useState([]);
  return (
    <>
      <div className="pt4 pb0" style={{ margin: 'auto' }}>
        <ReactToPrint
          trigger={() => (
            <button
              type="button"
              className="btn btn-small white black-text ml2 right"
            >
              Export to PDF
            </button>
          )}
          content={() => componentRef.current}
          documentTitle={`${patient.full_name} - Care Plan`}
        />
      </div>
      <Notes patientId={patient.id} callbackNotes={setNotes} />

      <div className="pt4 pb4" />

      <Actions patientId={patient.id} callbackActions={setActions} />

      <div className="pt4 pb4" />

      <Reminders patient={patient} />

      <div style={{ display: 'none' }}>
        <div ref={componentRef}>
          <CarePlanPDFView patient={patient} notes={notes} actions={actions} />
        </div>
      </div>
    </>
  );
};

CarePlan.propTypes = {
  patient: PropTypes.shape({
    id: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    weight: PropTypes.string.isRequired,
    weight_type: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    height_type: PropTypes.string.isRequired,
    high_risk: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    dob_date: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
    medications: PropTypes.string,
    conditions: PropTypes.string,
    treatments: PropTypes.string,
    full_name: PropTypes.string,
  }).isRequired,
};

export default CarePlan;
